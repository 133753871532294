import { Theme } from 'theme';
import { isMobile } from 'react-device-detect';

export default {
  headline: {
    fontFamily: Theme.primaryFontFamily,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    color: '#000000',
  },
  completeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: isMobile ? '80vh' : '50vh',
    textAlign: 'center',
  },
  formContainer: {
    paddingTop: 20,
  },
  pt15: {
    paddingTop: '15px',
  },
  icon: {
    color: Theme.success,
    margin: '0.25rem',
    fontSize: '60px'
  },
  footer: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    backgroundColor: '#FFFFFF',
    padding:'1rem 0',
    paddingRight: '2rem',
  },
  linkContainer: {
    textAlign: 'center',
    margin: '1rem'
  },
  link: {
    color: Theme.primary,
    textDecoration: 'underline'
  },
  steps: {
    marginTop: '3rem',
  }
} 