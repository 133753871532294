import API from 'api';

const makePaymentFree = () => {
  return {
    type: 'MAKE_PAYMENT_FREE',
  };
};

export const freeCheckout = async (data) => {
  makePaymentFree()
  try {
    const response = await API.post('/shoots/pay', data);
    return {
      type: 'MAKE_PAYMENT_FREE_SUCCESS',
      payload: response.data,
    };
  } catch (error) {
    return { type: 'MAKE_PAYMENT_FREE_FAILED', payload: null, error: error };
  }
};
