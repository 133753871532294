import { Theme } from 'theme';
import { isMobile } from 'react-device-detect';

export const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#3C3C4F',
    margin: '0.5rem',
    position: isMobile ? 'relative' : 'fixed',
    bottom: '.5rem',
    marginTop: '1rem',
    right: isMobile ? null : '0',
    width: isMobile ? '' : '',
  },
  icon: {
    color: Theme.primary,
    margin: '0.25rem',
    marginTop: isMobile? '' : '1rem',
    marginRight: isMobile? '' : '.5rem',
    fontSize: isMobile? '' : '1.5rem',
    cursor: 'pointer'
  },
  text: {
    color: Theme.primary,
    margin: '0.25rem',
    cursor: 'pointer',
  },
  chatContainer: {
    display: isMobile ? '': 'flex',
  }
};
