import React from 'react';
import {Typography, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import spinner from './spinner.gif';

const { Text, Title} = Typography;

const Loading = ({ text }) => {
  return (
      <Row justify="center">
        <Col className="text-center">
          <Title level={4}>
            <span>
              {text}
            </span>
          </Title>
          <img src={spinner} width="120" alt='logo-spinner'/>
        </Col>
      </Row>
  );
};

Loading.propTypes = {
  text_1: PropTypes.string,
  link_2: PropTypes.string,
};

export default Loading;
