import React from 'react';
import { Alert, Typography, Space} from 'antd';
import PropTypes from 'prop-types';
import { segmentTrack } from '../../actions/segment';
const { Text, Link } = Typography;


const BannerComponent = ({ type,bannerTitle, boldText, text, linkText, href }) => {

  const handleClick = () => {
    segmentTrack('Outbound Link', { url: {href} });
  }

  const bannerContent =(
  <>
    <Text strong type={type}>{boldText}&nbsp; {boldText ? <br/> : null}</Text>
    <Text>{text}{text ? " " : null}</Text>
    <Link  underline
      href={href}
      target="_blank"
      onClick={handleClick}
      rel="noopener noreferrer"
    >
      {linkText}
    </Link>
  </>
);

  return (
    <Alert type={type}
    message={bannerTitle}
    description={bannerContent}
    showIcon
    />
  );
};

BannerComponent.propTypes = {
  text: PropTypes.string,
  boldText: PropTypes.string,
  link: PropTypes.string,
};

export default BannerComponent;
