import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Specialist from 'components/Specialist';
import { MobileView, BrowserView} from 'react-device-detect';
import { Layout, Button, Typography } from 'antd';
import Booking from '../Booking';
import TextBox from 'components/TextBox';
import Line from 'components/Line';
import FooterText from 'components/FooterText';
import SelectPackage from 'components/SelectPackage';
import Included from 'components/Included';
import CarouselComponent from 'components/_Carousel';
import { Context } from 'context';
import { setProgress } from 'actions/progress';
import { segmentTrack } from 'actions/segment';
// import { styles } from 'components/Specialist/styles';
const { Footer } = Layout;
const { Title, Text} = Typography;

const styles = {
  footer: {
    background: 'white'
  }
}

const StepPackage = () => {
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const [showError, toggleError] = useState(false);

  const handleNext = () => {
    if (state.shoot.package.id) {
      history.push('/step/4');
      dispatch(setProgress(state.progress + 11));
      segmentTrack('Completed Checkout Step', {
        step: '3',
        name: 'gallery and package',
      });
    } else {
      toggleError(true);
    }
  };

  useEffect(() => {
    if (state.shoot.package.id) {
      toggleError(false);
    }
  }, [state.shoot.package.id]);

  return (
    <Booking>
      <div style={{marginBottom: '2rem'}}>
        <Title level={3} className={"display"} >Pick a Package</Title>
        <Text className="lead" >How long do you need your {state.shoot.scene.name} photographer for?</Text>
      </div>
      <div>
        <SelectPackage />
      </div>
      {showError ? (
        <div>
          <p >* Please select a package!</p>
        </div>
      ) : null}
      {/* <Footer style={styles.footer}> */}
        <Line />
        <div>
          <Button type="primary" block onClick={() => handleNext()} style={{margin: '2rem 0'}}>
            Next
          </Button>
        </div>
        <BrowserView>
          <FooterText text="We've delivered over 500,000 photos"/>
        </BrowserView>
        <MobileView>
          <Specialist />
        </MobileView>
      {/* </Footer> */}
    </Booking>
  );
};

export default StepPackage;
