import React, { useContext, useEffect } from 'react';
import { Layout, Input, Button, Typography, Row, Col, Checkbox, Tooltip, Collapse } from 'antd';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

import Specialist from 'components/Specialist';
import Booking from '../Booking';
import TextBox from 'components/TextBox';
import Line from 'components/Line';
import FooterText from 'components/FooterText';
import styles from './styles';
import { Context } from 'context';
import { segmentTrack } from 'actions/segment';
import { setProgress } from 'actions/progress';
import { setAdditionalDetails } from 'actions/general';
import {
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Theme } from 'theme';
import {
  setCheckedAddon,
  addToTotal,
  removeFromTotal,
  setPromoCode,
  applyPromoCode,
  removePromoCode,
  submitBooking,
  setShootAddons,
  toggleBookingLoading,
} from 'actions/booking';

const { TextArea } = Input;
const { Title, Text } = Typography;
const { Panel } = Collapse

const { Footer } = Layout;

const StepAddons = () => {
  const [state, dispatch] = useContext(Context);
  const history = useHistory();

  useEffect(() => {
    segmentTrack('Viewed Checkout Step', {
      step: '7',
      name: 'additional info',
    });
  });

  const setAddons = () => {
    let addons = [];
    state.shoot.package.addons.forEach((addon) => {
      if (addon.checked) {
        addons.push(addon.id);
      }
    });
    dispatch(setShootAddons(addons));
  };

  const addonHandler = (addon) => {
    // const addon = event.target.value;
    addon.checked = addon.checked ? false : true;
    if (addon.checked) {
      dispatch(addToTotal(addon.price));
      segmentTrack('Checkbox Interacted', { name: addon.name, action: true });
      setAddons();
    } else {
      dispatch(removeFromTotal(addon.price));
      setAddons();
      segmentTrack('Checkbox Interacted', { name: addon.name, action: false });
    }
    dispatch(setCheckedAddon(addon));
  };

  const handleTextArea = (event) => {
    const { value } = event.target;
    dispatch(setAdditionalDetails(value));
    segmentTrack('Input Interacted', { action: 'additional_details' });
  };

  const handleNext = () => {
    history.push('/step/9');
    dispatch(setProgress(state.progress + 11));
    segmentTrack('Completed Checkout Step', {
      step: '7',
      name: 'additional info',
    });
  };

  const handleClick = () => {
    segmentTrack('Outbound Link', {
      url: `https://print.kite.ly/perfocal/#/products`,
    });
  };

  return (
    <Booking>
      <div style={{marginBottom: '2rem'}}>
        <Title level={3} className={"display"} >Extras</Title>
        <Text className="lead" >Take a quick look, do any of these add-ons tickle your fancy?</Text>
      </div>

      <Col md={{ span: 24 }} lg={{ span: 23 }} style={styles.text}>
                    <Col span={24}>
                      <Collapse expandIconPosition={'right'} accordion>
                        {state.addons.map((addon, index) => {
                          let urgent = addon.name === 'Urgent Booking' ? true : false;
                          return (
                            <Panel
                              header={
                                <>
                                  <span onClick={(e) => e.stopPropagation()}>
                                    <Checkbox
                                      value={addon.name}
                                      checked={addon.checked}
                                      indeterminate={
                                        addon.name === 'Urgent Booking' ? addon : addon.checked
                                      }
                                      onChange={() => addonHandler(addon)}
                                      disabled={urgent}
                                    ></Checkbox>
                                  </span>
                                  <span style={{marginLeft: '1rem'}}>{addon.name} - <strong>£{addon.price/100}</strong></span>
                                </>
                              }
                              key={index}
                            >
                              {addon.description}
                            </Panel>
                          );
                        })}
                      </Collapse>
                    </Col>
                  </Col>


      {/* <Footer style={isMobile ? styles.footer : styles.footerWeb}> */}
        <Line />
        <div style={styles.buttons}>
          <Button type="primary" block onClick={() => handleNext()}>
            Next
          </Button>
        </div>
        <BrowserView>
          <FooterText text="We're rated Excellent on Trustpilot" />
        </BrowserView>
        <MobileView>
          <Line />
          <Specialist /> 
        </MobileView>
      {/* </Footer> */}
    </Booking>
  );
};

export default StepAddons;
