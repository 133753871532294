import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from 'theme';

const styles = {
  textContainer: {
    fontFamily: Theme.primaryFontFamily,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '0.2px',
    color: '#3C3C4F',
    paddingTop: 10,
  }
}

const TextInfo = ({ text, style }) => {
  return (
    <div style={{ ...styles.textContainer, ...style }} >
      {text}
    </div >
  )
}

TextInfo.propTypes = {
  text: PropTypes.string,
};

export default TextInfo;