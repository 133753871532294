import React, { useState, useEffect, useContext } from 'react';
import { Space, Typography} from 'antd';
import { MobileView, BrowserView } from 'react-device-detect';

import { Context } from 'context';
import Booking from '../Booking';
import Line from 'components/Line';
import FooterText from 'components/FooterText';
import SelectScene from 'components/SelectScene';
import Specialist from 'components/Specialist';

const { Text, Title} = Typography;

const StepScene = () => {
  const [state, dispatch] = useContext(Context);
  console.log(state)

  return (
    <Booking>
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <div>
        <Title level={3} className={"display"} >{state.purpose === 'For Personal' ? 'Personal Shoots' : 'Business Shoots'} </Title>
        <Text className="lead">Okay! Which type of {state.purpose === 'For Personal' ? 'personal' : 'business'} shoot are you looking for?</Text>
      </div>
      <SelectScene /> 
      <BrowserView>
      <FooterText text="We've served thousands of happy customers" />
      </BrowserView>
    </Space>
      <Line />
      <MobileView>
        <Specialist />
      </MobileView>
    </Booking>
  );
};

export default StepScene;
