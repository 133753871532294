import React, { createContext, useReducer } from 'react';
import BookingReducer from 'reducers/BookingReducer';
import * as actions from 'actions';
import * as DummyState from './dummyState';

let initialState = {
  shoot: {
    id: null,
    payment: {},
    addon_ids: [],
    address: '',
    scene: {},
    package: {},
    package_id: '',
    city: null,
    latitude: null,
    longitude: null,
    additional_details: null,
    postcode: null,
    starts_at: null,
    first_name: null,
    last_name: null,
    email: null,
    phone: '',
  },
  scenes: [],
  packages: [],
  progress: 12,
  step: 0,
  purpose: null,
  addons: null,
  payments: [],
  promocode: null,
  promoCodeError: null,
  isPromoCodeApplied: false,
  spinner: false,
  urgent: false,
  addedUrgent: false,
  isBookingLoading: false,
  error: null,
  isFreePaymentLoading: false,
  location: {
    center: {
      lat: 51.678853,
      lng: -0.186769,
    },
    zoom: 6,
    searchResultFound: false,
  }
};

const testMode = false;
if ( testMode === true ) {
  initialState = DummyState.state //inject dummy state if testMode is on
};

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(BookingReducer, initialState);
  return (
    <Context.Provider value={[state, dispatch, actions]}>
      {children}
    </Context.Provider>
  );
};

export const Context = createContext(initialState);

export default ContextProvider;
