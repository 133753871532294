import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Form, Button, Row, Col, Space, Typography, Input } from 'antd';
import { MobileView, isMobile, BrowserView } from 'react-device-detect';

import CustomisedPhoneInput from 'components/PhoneInput';

import Specialist from 'components/Specialist';
import { Context } from 'context';
import Booking from '../Booking';
import TextBox from 'components/TextBox';
import Line from 'components/Line';
import FooterText from 'components/FooterText';
import { setProgress } from 'actions/progress';
import { setValue } from 'actions/general';
import { segmentTrack, segmentIdentify } from 'actions/segment';
// import { newLead } from 'actions/customer-io';
import { zapierPost } from 'actions/zapier';
import { Domain } from 'config';

const { Footer } = Layout;
const { Title, Text} = Typography;

const StepContact = () => {
  const [state, dispatch] = useContext(Context);
  const [form] = Form.useForm();
  const history = useHistory();

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    segmentTrack('Input Interacted', { action: `${name}` });
    dispatch(setValue({ name, value }));
  };

  useEffect(() => {
    segmentTrack('Viewed Checkout Step', {
      step: '6',
      name: 'contact details',
    });
  });

  const handlePhoneInput = (value) => {
    dispatch(setValue({ name: 'phone', value: value }));
    form.setFieldsValue({phone_label: value})
    segmentTrack('Input Interacted', { action: 'phone' });
  };

  const onFinish = (values) => {
    if(!form.getFieldValue('phone_label')) {
      return form.validateFields(['phone_label']);
    }

    dispatch(setProgress(state.progress + 11));
    history.push('/step/7');
    segmentTrack('Completed Checkout Step', {
      step: '6',
      name: 'contact details',
    });
    segmentIdentify({ email: state.shoot.email });
    // newLead({
    //   first_name: state.shoot.first_name,
    //   last_name: state.shoot.last_name,
    //   email: state.shoot.email,
    //   phone: state.shoot.phone,
    //   scene_name: state.shoot.scene.name,
    //   package_name: state.shoot.package.name,
    //   scene_id: state.shoot.scene.id,
    //   package_id: state.shoot.package.id,
    //   purpose: state.purpose === 'For Personal' ? 'Personal' : 'Business',
    // });
    zapierPost({
      first_name: state.shoot.first_name,
      last_name: state.shoot.last_name,
      email: state.shoot.email,
      phone: state.shoot.phone,
      scene_name: state.shoot.scene.name,
      package_name: state.shoot.package.name,
      scene_id: state.shoot.scene.id,
      package_id: state.shoot.package.id,
      purpose: state.purpose === 'For Personal' ? 'Personal' : 'Business',
      admin_url: `https://admin.${Domain}/orders/${state.shoot.id}`,
      time_stamp: new Date(),
    });
  };

  return (
    <Booking>
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <div>
            <Title level={3} className={"display"} >👍&nbsp;Great! We have some awesome photographers available. </Title>
            <Text className="lead">How can they get in touch with you?</Text>
            </div>
            <Form
              form={form}
              layout="vertical"
              name="contact-form"
              onFinish={onFinish}
              initialValues={{
                first_name_label: state.shoot.first_name || null,
                last_name_label: state.shoot.last_name || null,
                email_label: state.shoot.email || null,
                phone_label: state.shoot.phone || null,
              }}
            >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} align="middle" justify="center">
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  xl={{ span: 10}}
                >
                  <Form.Item
                    name="first_name_label"
                    label="First name"
                    rules={[
                      { required: true, message: 'Please enter your first name' },
                    ]}
                  >
                    <Input
                      placeholder="e.g. Jane"
                      name="first_name"
                      onChange={handleOnChange}
                    />
                  </Form.Item>
                </Col>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  xl={{ span: 10}}
                >
                  <Form.Item
                    name="last_name_label"
                    label="Last name"
                    rules={[
                      { required: true, message: 'Please enter your last name' },
                    ]}
                  >
                    <Input
                      placeholder="e.g. Doe"
                      name="last_name"
                      onChange={handleOnChange}
                    />
                  </Form.Item>
                </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} align="middle" justify="center">
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  xl={{ span: 10}}
                >
                <Form.Item
                  name="phone_label"
                  label="Phone"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a valid phone number',
                    },
                  ]}
                >
                    <CustomisedPhoneInput
                      placeholder="Enter phone number"
                      value={state.shoot.phone ? state.shoot.phone.toString() : ''}
                      onChange={handlePhoneInput}
                    />
                </Form.Item>
                </Col>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  xl={{ span: 10}}
                >
                <Form.Item
                  name="email_label"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Please enter a valid email address',
                    },
                  ]}
                >
                  <Input
                    placeholder="e.g. jane@example.com"
                    name="email"
                    onChange={handleOnChange}
                  />
                </Form.Item>
                </Col>
            </Row>
            </Form>

            <Button
              link={'/step/8'}
              type="primary"
              form="contact-form"
              htmlType="submit"
              block
            >
              Next
            </Button>

            <BrowserView>
            <FooterText text="9/10 customers would recommend Perfocal" />
            </BrowserView>
            </Space>
            <Line />
          <MobileView>
            <Specialist />
          </MobileView>
    </Booking>
  );
};

export default StepContact;
