import React, { useContext, useState, useEffect } from 'react';
import { Layout, Form, DatePicker, TimePicker, Button, Space, Typography, Row, Col } from 'antd';
import { MobileView, BrowserView } from 'react-device-detect';

import Booking from '../Booking';
import { useHistory } from 'react-router-dom';
import Specialist from 'components/Specialist';
import Line from 'components/Line';
import BannerComponent from 'components/_Banner';
import FooterText from 'components/FooterText';
import Loading from 'components/Loading';
import { Context } from 'context';
import {
  setSelectedDate,
  setUrgentBooking,
  setSelectedTime,
  setUrgentAdded,
} from 'actions/date';
import moment from 'moment';
import { setProgress } from 'actions/progress';
import { setSpinner } from 'actions/general';
import { getAddons } from 'actions/scene';
import {
  setTimeString,
  configTimeError,
  configDateError,
  isUrgent,
} from '../../utils/Date';
import { segmentTrack } from 'actions/segment';
import {
  setCheckedAddon,
  setShootAddons,
  addToTotal,
  removeFromTotal,
} from 'actions/booking';

const { Title, Text} = Typography;

const timeFormat = 'HH:mm';

const StepDate = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [state, dispatch] = useContext(Context);

  let dateString = state.shoot.starts_at;
  const [time, setTime] = useState(setTimeString(dateString));
  const [configDate] = useState(configDateError(dateString));
  const [configTime, setConfigTime] = useState(configTimeError(dateString));


  useEffect(() => {
    const setAddons = () => {
      let addons = [];
      state.shoot.package.addons.forEach((addon) => {
        if (addon.checked) {
          addons.push(addon.id);
        }
      });
      dispatch(setShootAddons(addons));
    };

    const setUrgent = (boolean) => {
      dispatch(setUrgentBooking(boolean)); 
      let urgent = state.shoot.package.addons.filter(
        (addon) => addon.name === 'Urgent Booking'
      );
      if (state.urgent && !state.addedUrgent) {
        let addons = state.shoot.package.addons;
        dispatch(getAddons(addons));
        urgent[0].checked = true;
        dispatch(setCheckedAddon(urgent[0]));
        dispatch(addToTotal(urgent[0].price));
        dispatch(setUrgentAdded(true));
        setAddons();
      } else if (!state.urgent && state.addedUrgent) {
        urgent[0].checked = false;
        dispatch(setCheckedAddon(urgent[0]));
        dispatch(removeFromTotal(urgent[0].price));
        dispatch(setUrgentAdded(false));
        setAddons();
      } else if (!state.urgent && !state.addedUrgent) {
        setAddons();
      }
    };

    segmentTrack('Viewed Checkout Step', {
      step: '5',
      name: 'time and date picker',
    });

    if (isUrgent(state.shoot.starts_at)) {
      setUrgent(true);
    } else {
      if (!state.purpose) {
        history.push('/step/1');
      } else {
        let addons = state.shoot.package.addons.filter(
          (addon) => addon.name !== 'Urgent Booking'
        );
        dispatch(getAddons(addons));
        setUrgent(false);
      }
    }

    let dateString = state.shoot.starts_at;
    if (dateString) {
      setTime(setTimeString(dateString));
      setConfigTime(configTimeError(dateString));
    }

  }, [dispatch, history, state.purpose, state.shoot.package.addons, state.shoot.starts_at,   state.addedUrgent, state.urgent]);



  const onFinish = (fieldsValue) => {
    if (!configTime) {
      dispatch(setSpinner(true));
      setTimeout(() => {
        dispatch(setProgress(state.progress + 11));
        history.push('/step/6');
        dispatch(setSpinner(false));
        segmentTrack('Completed Checkout Step', {
          step: '5',
          name: 'time and date picker',
        });
      }, (Math.floor(Math.random() * 64) + 9) * 33); //random effect
    } else {
      form.resetFields(['time_label']);
      form.validateFields(['time_label']);
    }
  };

  const handleDate = (date) => {
    dispatch(setSelectedDate(date));
    segmentTrack('Input Interacted', { action: 'date' });
    if(isUrgent(moment(date).format(
      'YYYY-MM-DD'))) {
      form.setFieldsValue({time_label: null})
    }
  };

  const handleTime = (time) => {
    if (time) {
      dispatch(setSelectedTime(time));
    }
    form.setFieldsValue({time_label: time ? moment(time, timeFormat) : null})
    segmentTrack('Input Interacted', { action: 'time' });
  };

  const dateOnChange = (date, dateString) => {
    handleDate({dateString, time });
  };

  const timeOnChange = (time, timeString) => {
    if (time) {
      handleTime(`${timeString}:00`);
    } else {
      handleTime(null)
    }
  };

  const disabledDate = (current) => {
    return moment().add(-1, 'days') >= current;
  };

  const disabledTime = (current) => {
    const disabled = [0, 1, 2, 3, 4, 5]
    if (
      moment(state.shoot.starts_at).format('YYYY/MM/DD') ===
      moment().format('YYYY/MM/DD') 
    ) {
      for(let x = 6; x < moment().add(4, 'hours').get('hours'); ++x) {
        disabled.push(x)
      }
      return disabled
    }
    return disabled
  };

  return (
    <>
      <Booking>
      {state.spinner ? (
        <MobileView>
          <Loading text="Looking for photographers..." />
        </MobileView>
      ) : null}
        {state.spinner ? (
          <BrowserView>
            <Loading text="Looking for photographers..." />
          </BrowserView>
        ) : (
          <>
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <div>
            <Title level={3} className={"display"} >Finally, when is your shoot? </Title>
            <Text className="lead">When do you need your photographer? You can change this later if needed.</Text>
            </div>
            <Form
              onFinish={onFinish}
              form={form}
              layout="vertical"
              name="date-form"
              initialValues={{
                time_label: time ? moment(time, timeFormat) : null,
                date_label: state.shoot.starts_at
                  ? moment(state.shoot.starts_at, 'YYYY-MM-DD')
                  : null,
              }}
            >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} align="middle" justify="center">
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  xl={{ span: 10}}
                >
                  <Form.Item
                    name="date_label"
                    label="Shoot Date"
                    rules={[
                      {
                        required: true,
                        type: 'date',
                        message: 'Please enter a valid date',
                      },
                    ]}
                  >
                    <DatePicker
                      format={'DD-MMM-yyyy'}
                      disabledDate={disabledDate}
                      allowClear={true}
                      onChange={dateOnChange}
                      placeholder="Pick a shoot date"
                      className="mb--8"
                      style = {{ width: "100%"}}
                    />
                  </Form.Item>
                </Col>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  xl={{ span: 10}}
                >
                  <Form.Item
                    name="time_label"
                    label="Start Time"
                    rules={[
                      {
                        required: true,
                        type: 'date',
                        message: 'Please enter a valid time',
                      },
                    ]}
                  >
                    <TimePicker
                      showNow={false}
                      minuteStep={15}
                      value={time ?  moment(time, timeFormat): null}
                      disabledHours={() => disabledTime(state.shoot.starts_at)}
                      hideDisabledOptions = {true}
                      allowClear={true}
                      format={timeFormat}
                      onChange={timeOnChange}
                      placeholder="e.g. 15:00"
                      className="mb--8"
                      style = {{ width: "100%"}}
                    />
                    </Form.Item>
                </Col>
            </Row>
            {state.urgent ? (
              <BannerComponent
                type="warning"
                boldText="Urgent bookings with less than one day notice may incur an extra
                fee."
              />

            ) : null}

        </Form>
              <div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    form="date-form"
                  >
                    Next
                  </Button>
              </div>
              <BrowserView>
                <FooterText text="We're rated excellent on Trustpilot" />
              </BrowserView>
            </Space>
              <Line />
              <MobileView>
                <Specialist />
              </MobileView>
          </>
        )}
      </Booking>
    </>
  );
};

export default StepDate;
