import { Theme } from 'theme';
import { isMobile } from 'react-device-detect';

export default {
  headlineSpinner: {
    fontFamily: Theme.primaryFontFamily,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    color: '#000000',
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: isMobile ? '100vh' : '50vh',
    textAlign: 'center',
  },
  gif: {
    height: isMobile ? '200px' : '300px',
    width: isMobile ? '200px' : '300px',
    margin: '0',
    padding: '0',
  },
};
