import React, { useContext, useEffect } from 'react';
import { Layout, Input, Button, Typography } from 'antd';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

import Specialist from 'components/Specialist';
import Booking from '../Booking';
import TextBox from 'components/TextBox';
import Line from 'components/Line';
import FooterText from 'components/FooterText';
import styles from './styles';
import { Context } from 'context';
import { segmentTrack } from 'actions/segment';
import { setProgress } from 'actions/progress';
import { setAdditionalDetails } from 'actions/general';

const { TextArea } = Input;
const { Title, Text} = Typography;

const { Footer } = Layout;

const StepAdditional = () => {
  const [state, dispatch] = useContext(Context);
  const history = useHistory();

  useEffect(() => {
    segmentTrack('Viewed Checkout Step', {
      step: '7',
      name: 'additional info',
    });
  });

  const handleTextArea = (event) => {
    const { value } = event.target;
    dispatch(setAdditionalDetails(value));
    segmentTrack('Input Interacted', { action: 'additional_details' });
  };

  const handleNext = () => {
    history.push('/step/8');
    dispatch(setProgress(state.progress + 11));
    segmentTrack('Completed Checkout Step', {
      step: '7',
      name: 'additional info',
    });
  };

  const handleClick = () => {
    segmentTrack('Outbound Link', {
      url: `https://print.kite.ly/perfocal/#/products`,
    });
  };

  return (
    <Booking>
        <div style={{marginBottom: '1rem'}}>
        <Title level={3} className={"display"} >Additional Shoot Details</Title>
        <Text className="lead" >Is there anything else you can tell us to help us better prepare the photographer?</Text>
      </div>
      <div style={isMobile? styles.mainContainer : {}}>
      <div style={styles.formContainer}>
        <div style={styles.container}>
          <p style={styles.text}>
            Fancy your favourite images in a <b>frame, photobook</b> or on a{' '}
            <b>phone case?</b> We can do that for you! You can have your images{' '}
            <a
              style={styles.link}
              href="https://print.kite.ly/perfocal/#/products"
              target="_blank"
              onClick={handleClick}
              rel="noopener noreferrer"
            >
              professionally printed right from your Perfocal gallery!
            </a>
          </p>
        </div>
      </div>
      <div style={styles.formContainer}>
        <TextArea
          rows={isMobile ? 6 : 8}
          className="text-area-input"
          placeholder="How many guests? Indoors, outdoors? Multiple locations? Needs to be good around houses?"
          onChange={handleTextArea}
          defaultValue={state.shoot.additional_details || null}
        />
      </div>
      </div>
      {/* <Footer style={isMobile ? styles.footer : styles.footerWeb}> */}
        <Line />
        <div style={styles.buttons}>
          <Button block type="primary" ghost onClick={() => handleNext()}>
            Skip
          </Button>
          <div style={{ margin: '0 .25rem' }}></div>
          <Button type="primary" block onClick={() => handleNext()}>
            Next
          </Button>
        </div>
        <BrowserView>
          <FooterText text="We're rated Excellent on Trustpilot" />
        </BrowserView>
        <MobileView>
        <Line />
          <Specialist />
        </MobileView>
      {/* </Footer> */}
    </Booking>
  );
};

export default StepAdditional;
