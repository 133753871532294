import React from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import StepAdditional from 'containers/StepAdditional';
import StepComplete from 'containers/StepComplete';

import StepBookingSummary from 'containers/StepBookingSummary';
import StepContact from 'containers/StepContact';
import StepDate from 'containers/StepDate';
import StepMap from 'containers/StepMap';
import StepPackage from 'containers/StepPackage';
import StepPurpose from 'containers/StepPurpose';
import StepScene from 'containers/StepScene';
import StepAddons from 'containers/StepAddons';

const Router = () => {
  return (
    <BrowserRouter>
    <Redirect to="/step/1" />
      <Switch>
        <StepPurpose exact path="/step/1" />
        <StepScene path="/step/2" />
        <StepPackage path="/step/3" />
        <StepMap path="/step/4" />
        <StepDate path="/step/5" />
        <StepContact path="/step/6" />
        <StepAdditional path="/step/7" />
        <StepAddons path="/step/8" />
        <StepBookingSummary path="/step/9" />
        <StepComplete path="/step/10" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
