import React, { useContext, useState, useEffect } from 'react';

import Slider from 'react-slick';
import { Context } from 'context';
import { isMobile } from 'react-device-detect';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './style.css';

const style = {
  container: {
    height: isMobile ? '15rem' : '25rem',
    width: '100%',
  },
  img: {
    height: isMobile ? '15rem' : '25rem',
    width: '100%',
    objectFit: 'contain',
    color: 'rgb(83, 90, 224)',
    background: 'white',
  },
};

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const CarouselComponent = ({scene}) => {
  const [state,] = useContext(Context);
  const [gallery, changeGallery] = useState(
    scene.gallery
      ? shuffleArray(JSON.parse(scene.gallery)).slice(0, 6)
      : []
  );

  useEffect(() => {
    const galleryArray = scene.gallery ? shuffleArray(JSON.parse(scene.gallery)).slice(0, 6) : [];
    changeGallery(galleryArray)
  }, [scene.name, scene.gallery]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: true,
  };

  return (
    <Slider className="customSlider" {...settings} slickN>
      {gallery.map((img, index) => {
        return (
          <div key={index} style={style.container}>
            <img style={style.img} src={img} alt={`${scene.name}`} />
          </div>
        );
      })}
    </Slider>
  );
};

export default CarouselComponent;
