import API from 'api';

export const getScenes = async () => {
  const response = await API.get('/scenes');
  return { type: 'GET_SCENES', payload: response.data };
};

export const setPurpose = (purpose) => {
  return { type: 'SET_PURPOSE', payload: purpose };
};
 