import API from 'api';

export const setValue = (data) => {
  const { name, value } = data;
  return { type: 'SET_VALUE', payload: { value, name } };
};

export const setAdditionalDetails = (value) => {
  return { type: 'SET_ADDITIONAL_DETAILS', payload: value };
};

export const setLatLng = (value) => {
  return { type: 'SET_LOCATION', payload: value };
};

export const clearLocation = () => {
  return { type: 'CLEAR_LOCATION' };
};

export const requestCallback = async (payload) => {
  const response = await API.post('/shoots/request-callback', payload);
  return response;
};

export const setShootAddress = (address) => {
  return { type: 'SET_ADDRESS', payload: address };
}

export const setSpinner = (payload) => {
  return { type: 'SET_SPINNER', payload};
}

export const setShootCity = (payload) => {
  return { type: 'SET_CITY', payload };
};
