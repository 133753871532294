
export const Theme = {
  primaryColor: '#535ae0',
  primaryColor2: '#04CEE0',
  primaryColor3: '#FF8E88',

  // primary: 'rgb(83, 90, 224)',
  // success: 'rgb(54, 181, 117)',
  // warning: '#EFBD32',
  // error: 'rgb(252, 78, 107)',
  // appHeaderBgColor: '#FFFFFF',
}
