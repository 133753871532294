import React, { useEffect, useContext, useState } from 'react';
import { Context } from 'context';
import { Slider } from 'antd';
import { style } from './style';
import { setPackage, getAddons } from 'actions/scene';
import { isMobile } from 'react-device-detect';
import { segmentTrack } from 'actions/segment';
import { CheckCircleOutlined } from '@ant-design/icons';
import './style.css';

const styles = { 
  icon: {
  margin: '0.25rem',
  fontSize: '15px',
  }
}
const SelectPackage = () => {
  const [state, dispatch] = useContext(Context);
  const [packages, updatePackages] = useState([]);
  const [packageSelected, changePackage] = useState([]);

  const selectPackage = (index) => {
    changePackage(packages[index]);
    dispatch(setPackage(packages[index]));
    dispatch(getAddons(packages[index].addons));
    segmentTrack('Product Clicked', {
      product_id: packages[index].id,
      category: state.shoot.scene.name,
      name: packages[index].name,
      price: packages[index].price,
      quantity: '1',
    });
  };

  useEffect(() => {
    const sortedPackages = state.packages.sort((a,b) => parseFloat(a.price) - parseFloat(b.price));
    updatePackages(sortedPackages);
    if (!state.shoot.package.id || !state.packages.includes(state.shoot.package) )  {
      //pre-select most popular package
      let popular = state.packages.filter((pkg) => pkg.most_popular === true);
      if (popular[0]) {
        changePackage(popular[0]);
        dispatch(setPackage(popular[0]));
        dispatch(getAddons(popular[0].addons));
      }
    } else {
      changePackage(state.shoot.package);
      dispatch(setPackage(state.shoot.package));
      dispatch(getAddons(state.shoot.package.addons));
    }
    segmentTrack('Viewed Checkout Step', {
      step: '3',
      name: 'gallery and package',
    });
  }, [state.packages, state.shoot.package, dispatch]);

  const defaultPacakge = state.packages.findIndex((element) => element.id == state.shoot.package.id)
  let durations = [];
  state.packages.forEach(shootPackage => durations.push(shootPackage.duration.substring(0,2) + 'hr'));
  const marks = Object.assign({},durations)


  return (
    <div>
      <div style={{padding: '0 1rem'}}>
      <Slider style={style.slider} marks={marks} onChange={(i) => {selectPackage(i)}} value={defaultPacakge} step={null} max={Object.keys(durations).length - 1} tooltipVisible={false}/>
      </div>
      <div style={{height: '4rem'}}></div>
      {packageSelected.name ? (
        <div style={style.package} className='box-shadow-1'>
          {packageSelected.most_popular ? (
            <div style={style.popular}>Most Popular</div>
          ) : null}
          <div style={style.packagesText}>
            <b>{packageSelected.name} </b>{' - '}
            <b>{state.shoot.scene.name} </b>{' '}
            <b style={style.price}>&nbsp; £ {packageSelected.price / 100}</b>
          </div>
          <div style={style.description}>
            {packageSelected.description.split('\\n')[0].slice(2)}
            <br />
            Minimum of {packageSelected.description.match(/(\d+)\+/)[1]}
            {' edited photos delivered.'}
            <div><CheckCircleOutlined style={styles.icon} /> 48 hours delivery</div>
            <div><CheckCircleOutlined style={styles.icon} /> In-house natural editing</div>
            <div><CheckCircleOutlined style={styles.icon} /> Unlimited photos</div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SelectPackage;
