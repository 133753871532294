import { Theme } from 'theme';
import { isMobile } from 'react-device-detect';

export default {
  headline: {
    fontSize: '18px',
    color: '#3C3C4F',
    marginBottom: '.5rem',
  },
  bookingSummaryHeadline: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    color: Theme.primary,
    lineHeight: '19px',
    letterSpacing: '0.2px',
    paddingBottom: 5,
  },
  checkBoxInfo: {
    paddingLeft: 24,
    paddingTop: 0,
  },
  buttonLight: {
    fontWeight: Theme.fontWeight,
  },
  pt15: {
    paddingTop: '15px',
  },
  footer: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    padding: '1rem 0 0 0',
  },
  linkContainer: {
    textAlign: 'center',
    margin: '1rem',
  },
  lineContainer: {
    paddingTop: 24,
    paddingBottom: 24,
  },
  link: {
    color: Theme.primary,
    textDecoration: 'underline',
  },
  payment: {
    paddingBottom: '1rem'
  },
  brief: {
    color: Theme.primary,
    fontSize: '14px',
    cursor: 'pointer',
    paddingTop: '10px',
  },
  icon: {
    color: Theme.primary,
    margin: '0.25rem',
    marginLeft: '.5rem',
    fontSize: '1.25rem',
    cursor: 'pointer'
  },
  addon: {
    fontSize: '14px'
  },
  price: {
    color: Theme.primary,
    fontSize: '14px',
  },
  greenLabel: {
    color: 'rgb(54, 181, 117)',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  blackLabel: {
    color: '#3C3C4F',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  promoCode: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 18,
  },
  error: {
    color: Theme.error,
  },
};
