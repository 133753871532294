import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'antd';

import { Context } from 'context';
import { segmentTrack } from 'actions/segment';
import { setProgress } from 'actions/progress';
import { setPurpose } from 'actions/purpose';

const purposes = ['For Business', 'For Personal'];

const SelectPurpose = () => {
  let history = useHistory();
  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    segmentTrack('Viewed Checkout Step', { step: '1', name: 'purpose' });
  });

  const handleClick = (purpose) => {
    history.push('/step/2');
    dispatch(setPurpose(purpose));
    dispatch(setProgress(state.progress + 11));
    segmentTrack('Purpose Clicked', { purpose: purpose });
    segmentTrack('Completed Checkout Step', { step: '1', name: 'purpose' });
  };

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} align="middle">
      {purposes.map((purpose, index) => {
        let select = state.purpose === purpose ? false : true;
        return (
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            lg={{ span: 12 }}
            key={index}
          >
            <Button
              type=""
              size={"large"}
              className={select ? "mb--8" : "mb--8 ant-btn-primary"}
              block
              onClick={() => handleClick(purpose)}
            >
              {purpose}
            </Button>
          </Col>
        );
      })}
    </Row>
  );
};

export default SelectPurpose;
