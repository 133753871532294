import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Layout,
  Row,
  Col,
  Checkbox,
  Input,
  Button,
  Spin,
  Drawer,
  Tooltip,
  Card,
  Form,
  Image,
  Typography,
  Divider,
} from 'antd';
import {
  DoubleRightOutlined,
  ReloadOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { MobileView, BrowserView, isMobileOnly, isMobile } from 'react-device-detect';
import { format } from 'date-fns';
import { segmentTrack } from 'actions/segment';
import Specialist from 'components/Specialist';
import TrustBoxContainer from 'components/Trustpilot';
import FormHeader from 'components/FormHeader';
import Animate from 'rc-animate';
import { Context } from 'context';
import Booking from '../Booking';
import TextInfo from 'components/TextInfo';
import Label from 'components/Label';
import Line from 'components/Line';
import Spinner from 'components/Spinner';
import Payment from 'components/Payment';
import FooterText from 'components/FooterText';
import { freeCheckout } from 'actions/payment';
import { formatPrice } from 'utils/Numbers';
import styles from './styles';
import { Theme } from 'theme';
import {
  setCheckedAddon,
  addToTotal,
  removeFromTotal,
  setPromoCode,
  applyPromoCode,
  removePromoCode,
  submitBooking,
  setShootAddons,
  toggleBookingLoading,
} from 'actions/booking';
import ProLayout, { GridContent } from '@ant-design/pro-layout';




const { Content, Footer } = Layout;
const { Text, Title, Paragraph } = Typography;

const StepBookingSummary = () => {
  const [state, dispatch] = useContext(Context);
  const [shootObject] = useState(state.shoot);
  const [paymentVisible, togglePayment] = useState(false);
  const [spinner, toggleSpinner] = useState(false);

  const { shoot } = state;
  const {
    first_name,
    last_name,
    email,
    phone,
    additional_details,
    address,
    starts_at,
    scene,
  } = shoot;

  let history = useHistory();

  const makeFreeCheckout = async () => {
    toggleSpinner(true)
    const data = {
      shoot_id: state.shoot.id,
      payment_intent_id: state.payments[0].stripe_payment_intent,
      payment_intent_secret: state.payments[0].stripe_payment_intent_secret,
      promocode: state.promocode.name,
    };

    dispatch(await freeCheckout(data));
    if (!state.isFreePaymentLoading) {
      history.push('/step/10');
    }
  };

  const proceedToPayment = () => {
    if (shoot.payment.total_amount > 0) {
      togglePayment(true);
      segmentTrack('Completed Checkout Step', {
        step: '8',
        name: 'booking summary',
      });
    } else {
      makeFreeCheckout();
    }
  }; 

  const onChangePromoCode = (event) => {
    const promocode = event.target.value;
    dispatch(setPromoCode(promocode));
  };

  const promoCodeHandler = async () => {
    const { payments, promocodeText } = state;
    segmentTrack('Input Interacted', { action: 'promocode' });
    dispatch(
      await applyPromoCode(payments[0].stripe_payment_intent, promocodeText)
    );
  };

  const removePromoCodeHandler = () => {
    dispatch(removePromoCode());
  };

  useEffect(() => {
    async function sendBooking() {
      dispatch(toggleBookingLoading());
      dispatch(await submitBooking(shootObject));
    }
    sendBooking();
    segmentTrack('Viewed Checkout Step', {
      step: '8',
      name: 'booking summary',
    });
    if(state.promocode) {
      removePromoCodeHandler()
    }

  }, [dispatch, shootObject]);

  if (state.isBookingLoading) {
    return (
      <Booking>
        <Spinner />
      </Booking>
    );
  }

  if (!state.shoot.id && !state.isBookingLoading) {
    return (
      <Booking>
        <Row justify="center">
          <h2>Booking data not found..</h2>
        </Row>
        <Row justify="center">
          <Button type="primary" onClick={() => history.push('/step/1')}>
            <ReloadOutlined />
            Restart
          </Button>
        </Row>
      </Booking>
    );
  }


  let formContent;
  if (isMobileOnly) { //remove Card design on Mobile view
    formContent =
    <>
      <Animate transitionName="fade" transitionAppear>
        <Content>
        <FormHeader/>
          <Row>
            <Col span={24}>
              <Label text="Package"/>
            </Col>
            <Col span={24}>
              <TextInfo
                text={`${scene.name} - ${shoot.package.name} - ${shoot.package.duration}`}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Label text="Date & Time" />
            </Col>
            <Col span={24}>
              <TextInfo
                text={`${format(new Date(starts_at), 'hh:mm a, dd MMMM yyyy')}`}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Label text="Address" />
            </Col>
            <Col span={24}>
              <TextInfo text={`${address}`} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Label text="Additional Details" />
            </Col>
            <Col span={24}>
              <Paragraph ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}>
                {additional_details || ''}
              </Paragraph>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Label text="Email Address" />
              <TextInfo text={`${email}`} />
            </Col>
            <Col span={24}>
              <Label text="Your Name" />
              <TextInfo text={`${first_name} ${last_name}`} />
            </Col>
            <Col span={24}>
              <Label text="Phone Number" />
              <TextInfo text={`${phone}`} />
            </Col>
           
          </Row>
        <Line />

          <div style={{marginBottom: '1rem'}}>
            <Title level={3} className={"display"} >Checkout</Title>
          </div>
          <Row justify="space-between">
            <Col span={14} style={styles.pt18}>
              <Label
                text={`${shoot.package.name} ${shoot.scene.name} - ${shoot.package.duration}`}
              />
            </Col>
            <Col span={3} style={styles.pt18}>
              <TextInfo
                text={`£${formatPrice(shoot.package.price)}`}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              />
            </Col>
            {shoot.package.addons.map((addon, index) => {
              if (addon.checked) {
                return (
                  <React.Fragment key={index}>
                    <Col span={14} style={styles.pt18}>
                      <Label text={`${addon.name}`} />
                    </Col>
                    <Col span={3} style={styles.pt18}>
                      <TextInfo
                        text={`£${formatPrice(addon.price)}`}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      />
                    </Col>
                  </React.Fragment>
                );
              }
              return null;
            })}
            <Col span={12} style={styles.pt18}>
              <Label text="Natural Editing" />
            </Col>
            <Col span={3} style={styles.pt18}>
              <TextInfo text="Included" style={styles.greenLabel} />
            </Col>
            <Col span={12} style={styles.pt18}>
              <Label text="48 Hour Delivery" />
            </Col>
            <Col span={3} style={styles.pt18}>
              <TextInfo text="Included" style={styles.greenLabel} />
            </Col>
            {state.promocode && (
              <>
                <Col span={12} style={styles.pt18}>
                  <Label text={`Promocode: ${state.promocode.name}`} />
                </Col>
                <Col span={3} style={styles.pt18}>
                  <TextInfo
                    text={`${
                      state.promocode.percentage
                        ? `-%${state.promocode.value}`
                        : `-£${formatPrice(state.promocode.value)}`
                    }`}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      color: 'rgb(54, 181, 117)',
                    }}
                  />
                </Col>
              </>
            )}
            <Col span={24} style={{ ...styles.promoCode, ...styles.pt18 }}>
              <Input placeholder="Enter Promocode" onChange={onChangePromoCode} />
              {state.isPromoCodeApplied ? (
                <Button type="primary" style={{ marginLeft: isMobile ? '' : '.75rem' }} onClick={removePromoCodeHandler}>
                  Remove
                </Button>
              ) : (
                <Button
                  type="primary"
                  style={{ marginLeft: isMobile ? '' : '.75rem' }}
                  onClick={async () => await promoCodeHandler()}
                >
                  Apply
                </Button>
              )}
            </Col>
          
            <Col span={15}>
              <div style={{ color: 'red' }}>{state.promoCodeError}</div>
            </Col>
          </Row>
          <Divider/>
          <Row justify="space-between" style={styles.payment}>
            <Col span={12} style={styles.pt18}>
              <Label text="TOTAL" />
            </Col>
            <Col span={4} style={{textAlign:'right'}}>
              <b style={styles.price}>
                {shoot.payment.total_amount === 0 ? 'Free' : `£ ${formatPrice(shoot.payment.total_amount)}`}
              </b>
            </Col>
          </Row>
          {shoot.payment.total_amount === 0 ?
            <Button  type="primary" block onClick={() => makeFreeCheckout()}>
             {!spinner ? 'Proceed' : <Spin />}
            </Button>
          :
            <Payment />
          }
          
        </Content>
      </Animate>
      <TrustBoxContainer />
    </>
  } else {
    formContent =
    <GridContent contentWidth="Fixed">
      <div style={{display: 'flex'}}>
        <Card bordered={false} className="box-shadow-1" style={{width: '60%', margin: '1rem', height: 'fit-content'}}>
          <FormHeader/>
          <Animate transitionName="fade" transitionAppear>
            <Content>
              <Row>
                <Col span={24}>
                  <Label text="Package"/>
                </Col>
                <Col span={24}>
                  <TextInfo
                    text={`${scene.name} - ${shoot.package.name} - ${shoot.package.duration}`}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Label text="Date & Time" />
                </Col>
                <Col span={24}>
                  <TextInfo
                    text={`${format(new Date(starts_at), 'hh:mm a, dd MMMM yyyy')}`}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Label text="Address" />
                </Col>
                <Col span={24}>
                  <TextInfo text={`${address}`} />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Label text="Additional Details" />
                </Col>
                <Col span={24}>
                  <Paragraph ellipsis={{ rows: 1, expandable: true, symbol: 'more' }} style={{fontSize: '14px'}}>
                    {additional_details || ''}
                  </Paragraph>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Label text="Email Address" />
                  <TextInfo text={`${email}`} />
                </Col>
                <Col span={8}>
                  <Label text="Your Name" />
                  <TextInfo text={`${first_name} ${last_name}`} />
                </Col>
                <Col span={8}>
                  <Label text="Phone Number" />
                  <TextInfo text={`${phone}`} />
                </Col>
               
              </Row>
              <div style={styles.lineContainer}>
                <Line />
              </div>
            </Content>
          </Animate>
          <BrowserView>
            <Specialist />
          </BrowserView>
        </Card>

        <Card bordered={false} className="box-shadow-1" style={{width: '40%', margin: '1rem'}}>
          <Animate transitionName="fade" transitionAppear>
            <Content>
                <div style={{marginBottom: '1rem'}}>
                  <Title level={3} className={"display"} >Checkout</Title>
                </div>
                <Row justify="space-between">
                  <Col span={14} style={styles.pt18}>
                    <Label
                      text={`${shoot.package.name} ${shoot.scene.name} - ${shoot.package.duration}`}
                    />
                  </Col>
                  <Col span={3} style={styles.pt18}>
                    <TextInfo
                      text={`£${formatPrice(shoot.package.price)}`}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    />
                  </Col>
                  {shoot.package.addons.map((addon, index) => {
                    if (addon.checked) {
                      return (
                        <React.Fragment key={index}>
                          <Col span={14} style={styles.pt18}>
                            <Label text={`${addon.name}`} />
                          </Col>
                          <Col span={3} style={styles.pt18}>
                            <TextInfo
                              text={`£${formatPrice(addon.price)}`}
                              style={{ display: 'flex', justifyContent: 'flex-end' }}
                            />
                          </Col>
                        </React.Fragment>
                      );
                    }
                    return null;
                  })}
                  <Col span={12} style={styles.pt18}>
                    <Label text="Natural Editing" />
                  </Col>
                  <Col span={3} style={styles.pt18}>
                    <TextInfo text="Included" style={styles.greenLabel} />
                  </Col>
                  <Col span={12} style={styles.pt18}>
                    <Label text="48 Hour Delivery" />
                  </Col>
                  <Col span={3} style={styles.pt18}>
                    <TextInfo text="Included" style={styles.greenLabel} />
                  </Col>
                  {state.promocode && (
                    <>
                      <Col span={12} style={styles.pt18}>
                        <Label text={`Promocode: ${state.promocode.name}`} />
                      </Col>
                      <Col span={3} style={styles.pt18}>
                        <TextInfo
                          text={`${
                            state.promocode.percentage
                              ? `-%${state.promocode.value}`
                              : `-£${formatPrice(state.promocode.value)}`
                          }`}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            color: 'rgb(54, 181, 117)',
                          }}
                        />
                      </Col>
                    </>
                  )}
                  <Col span={24} style={{ ...styles.promoCode, ...styles.pt18 }}>
                    <Input placeholder="Enter Promocode" onChange={onChangePromoCode} />
                    {state.isPromoCodeApplied ? (
                      <Button type="primary" style={{ marginLeft: isMobile ? '' : '.75rem' }} onClick={removePromoCodeHandler}>
                        Remove
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        style={{ marginLeft: isMobile ? '' : '.75rem' }}
                        onClick={async () => await promoCodeHandler()}
                      >
                        Apply
                      </Button>
                    )}
                  </Col>
                
                  <Col span={15}>
                    <div style={{ color: 'red' }}>{state.promoCodeError}</div>
                  </Col>
                </Row>
                <Divider/>
                <Row justify="space-between" style={styles.payment}>
                  <Col span={12} style={styles.pt18}>
                    <Label text="TOTAL" />
                  </Col>
                  <Col span={4} style={{textAlign:'right'}}>
                    <b style={styles.price}>
                      {shoot.payment.total_amount === 0 ? 'Free' : `£ ${formatPrice(shoot.payment.total_amount)}`}
                    </b>
                  </Col>
                </Row>
                {shoot.payment.total_amount === 0 ?
                  <Button  type="primary" block onClick={() => makeFreeCheckout()}>
                   {!spinner ? `Proceed` : <Spin />}
                  </Button>
                :
                  <Payment />
                }
            </Content>
          </Animate>
          <BrowserView>
            <Specialist />
          </BrowserView>
        </Card>
      </div>
      <TrustBoxContainer />
    </GridContent>
  }

  console.log(state,'SSS')

  return (

    <ProLayout
    layout="top" //switch navigation menu mode -> 'side' | 'top'
    fixedHeader={false} // whether to fix the header (navigation bar) while scroll
     //switch PageContainer mode -> 'Fluid' | 'Fixed', Fluid = responsive width, Fixed = max 1200px width fixed and switches to responsive width at 'lg' breakpoint by default; breakpoint is customisable;
    title="Perfocal"
    navTheme = "light"
    collapsedButtonRender={false}
    menuHeaderRender = { () => (
      <div className="logo">
        <Image
        preview={false}
        width={136}
        alt="Perfocal logo"
        src="https://dkk5oy4zs0vc4.cloudfront.net/logo-dark.svg"/>
        <Text className="logo-motto">
          Everyone's perfect photographer.
        </Text>
      </div>
    )} // menuHeaderRender will override menu head with injected ReactNode block; you can pass in a `false` value into any method that ends with `Render` in its names to toggle off rendering
    menuRender = {false} //turn off menu as not needed in current booking form
    footerRender={() => ( //footerRender renders the footer and adds it after PageContainer
      <Footer style={{ textAlign: 'center' }}>
        <Image
        preview={false}
        width={24}
        alt="Perfocal symbol logo"
        src="https://dkk5oy4zs0vc4.cloudfront.net/iconmark-dark.svg"/>
        <div>
          <Text type="secondary" className="text-small">
            © {new Date().getFullYear()} Perfocal Limited
          </Text>
        </div>
      </Footer>
    )}
    >
      {formContent}

    </ProLayout>
  );
};

export default StepBookingSummary;
