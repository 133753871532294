import React, { useEffect, useContext, useCallback } from 'react';
import { Layout, Typography, Space } from 'antd';
import { isMobile, MobileView, BrowserView } from 'react-device-detect';

import Booking from '../Booking';
import TextBox from 'components/TextBox';
import Line from 'components/Line';
import dvd from './dvd.svg'
import FooterText from 'components/FooterText';
import BannerComponent from 'components/_Banner';
import SelectPurpose from 'components/SelectPurpose';
import Specialist from 'components/Specialist';
import { Context } from 'context';
import { getScenes } from 'actions/purpose';
import { setStep } from 'actions/steps';

const { Footer } = Layout;
const { Title, Text } = Typography;

const StepPurpose = () => {
  const [, dispatch] = useContext(Context);

  const setStepAndGetScenes = useCallback(async () => {
    dispatch(await setStep(2));
    dispatch(await getScenes());
  }, [dispatch]);

  useEffect(() => {
    setStepAndGetScenes();
  }, [dispatch, setStepAndGetScenes]);

  return (
    <Booking>



    <Space direction="vertical" size="large" style={{ width: "100%"}}>
      <div>
      <Title level={3} className={"display"} >Good day! 👋 Let's find you a great photographer. </Title>
      <Text className="lead">Firstly, what are you hiring a photographer for?</Text>
      <Text className="lead">
        <span className="wistia_embed wistia_async_twcne895tx popover=true popoverContent=link" >
          <a data-track-event-name="Watch Video Button Clicked" data-track-prop-count="1" href="#" class="btn btn-outline-primary-3 btn-lg mx-sm-2 mx-lg-0 mr-lg-2 my-1 my-sm-0 d-flex justify-content-center">
            <div class="d-flex align-items-center ">
              <a href="#" >
                {' '}Watch our video explainer
                {' '}<img src={dvd}/>
              </a>
            </div>
          </a>
        </span>
      </Text>
      </div>
        <SelectPurpose />
        <BannerComponent
          type="info"
          bannerTitle=""
          boldText="Businesses needing more than one shoot?"
          text="We offer bulk booking discounts!"
          linkText="Get in touch here if interested."
          href="https://www.perfocal.com/business/"
        />
        <BrowserView>
        <FooterText text="9/10 customers would recommend Perfocal" />
        </BrowserView>
      </Space>
        <Line />
        <MobileView>
          <Specialist />
        </MobileView>
    </Booking>
  );
};

export default StepPurpose;
