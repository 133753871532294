import { Domain } from 'config';

export const zapierPost = async (payload) => {
  if (Domain === 'perfocal.com') {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open('POST', 'https://hooks.zapier.com/hooks/catch/5026744/orb4ub4/');
      xhr.send(JSON.stringify(payload));
    } catch (e) {
      console.error(e);
    }
  }
};
