import API from 'api';

export const getPackages = async (id) => {
  const response = await API.get(`scenes/${id}/packages`);
  return { type: 'GET_PACKAGES', payload: response.data };
};

export const setScene = (scene) => {
  return { type: 'SET_SCENE', payload: scene };
};

export const setPackage = (pkg) => {
  return { type: 'SET_PACKAGE', payload: pkg };
};

export const getAddons = (addons) => {
  return { type: 'GET_ADDONS', payload: addons };
};