import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Booking from '../Booking';
import styles from './styles';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { isMobile } from 'react-device-detect';
import { Steps } from 'antd';

const { Step } = Steps;

const StepComplete = () => {
  let history = useHistory();

  useEffect(() => {
    history.listen((location) => {
      if (location.pathname !== '/step/10') {
        history.push('/step/10');
      }
    });
  }, [history]);

  return (
    <Booking complete={true}>
      <div style={styles.completeContainer}>
        <div>
          <div style={{ marginBottom: '1.5rem' }}>
            <CheckCircleOutlined style={styles.icon} />
          </div>
          <div>
            <span style={styles.headline}>Payment Complete</span>
          </div>
          <div>
            <p>To access your account, <b>check your email</b></p>
          </div>
          <div style={{ marginBottom: '1.5rem' }}>
            <Button
              type="primary"
              href="https://my.perfocal.com"
              className="button"
            >
              Go To My Portal
            </Button>
          </div>
          <Steps
            current={0}
            style={styles.steps}
            size={'small'}
            direction={isMobile ? 'vertical' : 'horizontal'}
          >
            <Step
              title="Get Matched"
              description="We’re pairing you with the best local photographer"
            />
            <Step
              title="Get Chatting"
              description="The best photos are a result of good communication."
            />
            <Step
              title="Get Ready"
              description="Prepare for your shoot and meet your photographer."
            />
          </Steps>
        </div>
      </div>
    </Booking>
  );
};

export default StepComplete;
