import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { Context } from 'context';
import { Typography, Row, Col, Progress, Button, Space } from 'antd';
import { Theme } from 'theme';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { setProgress } from 'actions/progress';


const Title = Typography.Title


const FormHeader = ({ complete }) => {
  let history = useHistory();
  const [state, dispatch] = useContext(Context);

  const handleClick = async () => {
    history.goBack();
    dispatch(setProgress(state.progress - 11));
  };

  return (
    <>
    <Row className="pb--24" align="top" style={{"height":"60px","alignItems":"center"}}>
    {state.spinner ? null : (
    <>
    {complete || history.location.pathname === '/step/9' ? null : (
        <>
          {history.location.pathname !== '/step/1' ? (
            <Col flex="48px" onClick={() => handleClick()} >
            <Button type="text" size={"small"} icon={<ArrowLeftOutlined />} />
            </Col>
          ) : null}
        </>

      )}
      {complete ? null : (
        <>
          {history.location.pathname === '/step/9' ? (
            <>
            <Col flex="48px" onClick={() => handleClick()} >
            <Button type="text" size={"small"} icon={<ArrowLeftOutlined />} />
            </Col>
            <Col flex="auto" >
            <Title level={3} className={"display"} style={{margin: '0'}} >Booking Summary</Title>
            </Col>
            </>
          ) : null}
        </>

      )}
      <Col flex="auto" >
        {complete || history.location.pathname === '/step/9' ? null : (
          <>
            {state.spinner ? null :
              <Progress percent={state.progress} showInfo={false} size="small" status="active"/>
            }
          </>
        )}
      </Col>
    </>
    )}
    </Row>
    </>
  );
};

export default FormHeader;
