import React, { useContext } from 'react';
import { Context } from 'context';
import FormHeader from 'components/FormHeader';

import { Layout, Card, Row, Col, Divider, Image, Typography, Space} from 'antd';
import ProLayout, { GridContent } from '@ant-design/pro-layout';
import { MobileView, BrowserView, isMobileOnly } from 'react-device-detect';
import Specialist from 'components/Specialist';
import TrustBoxContainer from 'components/Trustpilot';

import Animate from 'rc-animate';

const { Content, Footer } = Layout;
const { Text, Title } = Typography;

const Booking = ({ children, complete }) => {
  const [state] = useContext(Context);

  let formContent;
  if (isMobileOnly) { //remove Card design on Mobile view
    formContent =
    <>
      <FormHeader complete={complete} />
      <Animate transitionName="fade" transitionAppear>
        <Content>
            {children}
        </Content>
      </Animate>
      <TrustBoxContainer />
    </>
  } else {
    formContent =
    <GridContent contentWidth="Fixed">
      <Card bordered={false} className="box-shadow-1">
        <FormHeader complete={complete} />
        <Animate transitionName="fade" transitionAppear>
          <Content>
              {children}
          </Content>
        </Animate>
        <BrowserView>
          <Specialist />
        </BrowserView>
      </Card>
      <TrustBoxContainer />
    </GridContent>
  }

  return (
    <ProLayout
    layout="top" //switch navigation menu mode -> 'side' | 'top'
    fixedHeader={false} // whether to fix the header (navigation bar) while scroll
     //switch PageContainer mode -> 'Fluid' | 'Fixed', Fluid = responsive width, Fixed = max 1200px width fixed and switches to responsive width at 'lg' breakpoint by default; breakpoint is customisable;
    title="Perfocal"
    navTheme = "light"
    collapsedButtonRender={false}
    menuHeaderRender = { () => (
      <div className="logo">
        <Image
        preview={false}
        width={136}
        alt="Perfocal logo"
        src="https://dkk5oy4zs0vc4.cloudfront.net/logo-dark.svg"/>
        <Text className="logo-motto">
          Everyone's perfect photographer.
        </Text>
      </div>
    )} // menuHeaderRender will override menu head with injected ReactNode block; you can pass in a `false` value into any method that ends with `Render` in its names to toggle off rendering
    menuRender = {false} //turn off menu as not needed in current booking form
    footerRender={() => ( //footerRender renders the footer and adds it after PageContainer
      <Footer style={{ textAlign: 'center' }}>
        <Image
        preview={false}
        width={24}
        alt="Perfocal symbol logo"
        src="https://dkk5oy4zs0vc4.cloudfront.net/iconmark-dark.svg"/>
        <div>
          <Text type="secondary" className="text-small">
            © {new Date().getFullYear()} Perfocal Limited
          </Text>
        </div>
      </Footer>
    )}
    >
      {formContent}

    </ProLayout>
  );
};

export default Booking;
