import React, { useState, useContext } from 'react';
import { Context } from 'context';
import { InfoCircleOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-number-input';
import { styles } from './styles';
import './style.css';
import { setValue, requestCallback } from 'actions/general';
import { segmentTrack } from 'actions/segment';
import { isMobile } from 'react-device-detect';
import { IntercomAppId } from 'config';
import Intercom from 'react-intercom';
import { IntercomAPI } from 'react-intercom';
import Animate from 'rc-animate';
import CustomisedPhoneInput from 'components/PhoneInput';

const Specialist = () => {
  const [state, dispatch] = useContext(Context);

  const [show, toggleOptions] = useState(false);
  const [callback, toggleCallback] = useState(false);
  const [thanks, toggleThanks] = useState(false);
  const [intercom, initialise] = useState(false);

  const showOptions = (toggle) => {
    toggle
      ? segmentTrack('Chat Bubble Clicked', { action: 'open' })
      : segmentTrack('Chat Bubble Clicked', { action: 'close' });
    toggleOptions(toggle);
  };

  const handlePhoneInput = (value) => {
    dispatch(setValue({ name: 'phone', value: value }));
  };

  const callbackNow = () => {
    requestCallback({
      phone: state.shoot.phone || null,
      first_name: state.shoot.first_name || null,
      last_name: state.shoot.last_name || null,
      email: state.shoot.email || null,
      scene_name: state.shoot.scene.name || null,
      package_name: state.shoot.package.name || null,
    });

    toggleCallback(false);
    toggleThanks(true);

    setTimeout(() => {
      toggleThanks(false);
      toggleOptions(false);
    }, 4000);
  };

  const loadIntercom = () => {
    initialise(true);
    toggleOptions(false);
    setTimeout(() => {
      IntercomAPI('boot', {
        app_id: IntercomAppId,
      });
      IntercomAPI('onHide', function () {
        IntercomAPI('shutdown');
        initialise(false);
      });
      IntercomAPI('showNewMessage');
    }, 2000);
  };

  return (
    <div style={styles.container}>
      <div onClick={() => showOptions(true)} style={styles.chatContainer}>
        <InfoCircleOutlined style={styles.icon} />
        <span style={styles.text}>
          Have a question?{' '}
          {isMobile ? (
            'Speak to a specialist'
          ) : (
            <>
              <br />
              Speak to a specialist
            </>
          )}
        </span>
      </div>
      {show ? (
      <Animate transitionName="fade" transitionAppear transitionLeave> 
        <div>
          <div className="chat-option-1" onClick={() => showOptions(false)}>
            Close
          </div>
          <div className="chat-option-2" onClick={() => loadIntercom()}>
            Start a live chat
          </div>
          <div
            className="chat-option-3"
            onClick={() => {
              showOptions(false);
              toggleCallback(true);
            }}
          >
            Request a callback
          </div>
          <div className="chat-option-4">
            <a style={{ color: 'white' }} href="tel:+442080898087">
              Speak to a specialist
            </a>
          </div>
        </div>
      </Animate>
        
      ) : null}
      {callback ? (
        <>
          <div
            className="chat-option-6"
            onClick={() => {
              showOptions(false);
              toggleCallback(false);
            }}
          >
            Close
          </div>
          <div className="chat-option-5">
            <CustomisedPhoneInput 
            disableDropdown={true}
            countryCodeEditable={true}
            onChange={handlePhoneInput}/>
          </div>
          <div
            className="chat-option-4"
            onClick={() => {
              callbackNow();
            }}
          >
            Submit
          </div>
        </>
      ) : null}
      {thanks ? (
        <div
          className="chat-option-4"
          onClick={() => {
            this.setState({ askedHelp: !this.state.askedHelp });
          }}
        >
          <span role="img" aria-label="smile">
            Thanks! We'll be in touch very soon 😊
          </span>
        </div>
      ) : null}
      <div className="app">
        {intercom ? <Intercom appID={IntercomAppId} /> : null}
      </div>
    </div>
  );
};

export default Specialist;
