import React from 'react';
import spinner from './spinner.gif';
import styles from './styles';

const Spinner = () => {
  return (
    <div style={styles.loaderContainer}>
      <div>
        <img src={spinner} style={styles.gif} alt='spinner'/>
      </div>
    </div>
  );
};

export default Spinner;
