import React from 'react';
import ContextProvider from 'context';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import './App.less';
import Routes from './routes';
import { APP_ENV, GTMContainer } from 'config';
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: `${GTMContainer}`
}
 
TagManager.initialize(tagManagerArgs)

Sentry.init({
  dsn:
    'https://81f1334ec4894b5c88a834d6e9b83826@o364166.ingest.sentry.io/5455671',
  integrations: [new Integrations.BrowserTracing()],
  environment: APP_ENV,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

function App() {
  return (
    <ContextProvider>
      <Routes />
    </ContextProvider>
  );
}

export default App;
