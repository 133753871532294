export const setSelectedDate = (date) => {
  return { type: 'SET_SELECTED_DATE', payload: date };
};

export const setSelectedTime = (time) => {
  return { type: 'SET_SELECTED_TIME', payload: time };
};

export const setUrgentBooking = (payload) => {
  return { type: 'SET_URGENT_BOOKING', payload };
};

export const setUrgentAdded = (payload) => {
  return { type: 'SET_URGENT_ADDED', payload };
};