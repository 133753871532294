import { Theme } from 'theme';

export const style = {
  hourContainer: {
    display: 'flex',
    border: '1px solid rgb(240, 240, 240)',
    borderRadius: '1px',
    padding: '0',
    maxWidth: '100%',
  },
  hour: {
    border: '1px solid rgb(240, 240, 240)',
    textAlign: 'center',
    fontWeight: '600',
    // maxHeight: '100%',
    width: '100%',
    maxWidth: '100%',
    padding: '.5rem',
    cursor: 'pointer',
  },
  hourSelected: {
    border: `1px solid ${Theme.primary}`,
    backgroundColor: '#586cfc',
    textAlign: 'center',
    color: 'white',
    fontWeight: '600',
    width: '100%',
    maxWidth: '100%',
    padding: '.5rem',
    cursor: 'pointer',
  },
  package: {
    position: 'relative',
    alignItems: 'center',
    padding: '1rem',
    border: '1px solid rgb(240, 240, 240)',
    borderRadius: '1px',
    marginTop: '4rem',
    maxWidth: '40rem',
    margin: '0 auto',
    background: '#F2F2F2'
  },
  contain: {
    display:'flex'
  },
  description: {
    textAlign: 'left'
  },
  packagesText: {
    textAlign: 'initial',
  },
  slider: {
    fontWeight: '5rem',
    maxWidth: '40rem',
    margin: '0 auto'
  },
  icon: {
    color: Theme.primaryColor,
  },
  price: {
    color: Theme.primaryColor,
    fontSize: '18px',
    float: 'right'
  },
  popular: {
    position: 'absolute',
    right: '5px',
    top: '-10px',
    border: `1px solid ${Theme.primaryColor}`,
    borderRadius: '3px',
    background: Theme.primaryColor,
    fontWeight: '600',
    padding: '.25rem',
    color: 'white',
    fontSize: '12px',
  },
};
