import React, { useState, useContext, useEffect } from 'react';
import { Context } from 'context';
import { Alert, Input, Button, Space, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { MobileView, BrowserView } from 'react-device-detect';
import { segmentTrack } from 'actions/segment';

import Specialist from 'components/Specialist';
import Booking from '../Booking';
import Line from 'components/Line';
import FooterText from 'components/FooterText';

import Map from 'components/Map';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { setProgress } from 'actions/progress';
import { setLatLng, clearLocation, setShootAddress, setShootCity } from 'actions/general';
import Animate from 'rc-animate';

const { Search } = Input;
const { Title, Text} = Typography;

const StepMap = () => {
  const [state, dispatch] = useContext(Context);
  const { location } = state;
  const [mapStatus, setMapStatus] = useState(false);
  const [address, setAddress] = useState(state.shoot.address);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const [showError, toggleError] = useState(false);
  const history = useHistory();

  const handleChange = (address) => {
    if (address === '') {
      dispatch(clearLocation());
    }
    setAddress(address);
  };

  const handleMapSearch = (latLng) => {
    dispatch(setLatLng({ latLng }));
  };

  const handleSelect = (address) => {
    segmentTrack('Input Interacted', { action: 'map' });
    setAddress(address);
    toggleError(false);
    geocodeByAddress(address)
      .then((results) => {
        dispatch(setShootAddress(results[0].formatted_address));
        dispatch(setShootCity(results[0]));
        return getLatLng(results[0]);
      })
      .then((latLng) => handleMapSearch(latLng))
      .catch((error) => console.error('Error', error));
  };

  const autoCompleteOptions = {
    componentRestrictions: {
      country: 'uk',
    },
  };

  const handleNext = () => {
    if (state.shoot.address) {
      history.push('/step/5');
      dispatch(setProgress(state.progress + 11));
      segmentTrack('Completed Checkout Step', {
        step: '4',
        name: 'map picker',
      });
    } else {
      toggleError(true);
    }
  };

  useEffect(() => {
    segmentTrack('Viewed Checkout Step', { step: '4', name: 'map picker' });
    setTimeout(() => {
      setIsAlertOpen(location.searchResultFound);
    }, 500)
  });


  return (
    <Booking>
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <div>
      <Title level={3} className={"display"} >Where is the shoot? </Title>
      <Text className="lead">Where should your photographer meet you? You can update this later.</Text>
      </div>
      <Space direction="vertical" style={{ width: "100%" }}>
        {mapStatus && (
          <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
            searchOptions={autoCompleteOptions}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
            }) => (
              <div style={{ position: "relative"}}>
                <Search
                  placeholder="Search"
                  {...getInputProps({
                    placeholder: 'Search for addresses ...',
                    className: 'location-search-input',
                  })}
                />

                <div className="autocomplete-dropdown-container">
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    const style = suggestion.active
                      ? {
                          backgroundColor: '#f8f7f9',
                          cursor: 'pointer',
                          padding: 15,
                        }
                      : {
                          backgroundColor: '#ffffff',
                          cursor: 'pointer',
                          padding: 15,
                        };
                    return (
                      <React.Fragment key={suggestion.placeId}>
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                        <Animate transitionName="fade" transitionAppear>
                          <span>{suggestion.description}</span>
                          </Animate>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        )}

        {isAlertOpen && (
          <Animate transitionName="fade" transitionAppear>
            <Alert
              message="Great news! Perfocal has coverage here!"
              type="success"
              showIcon
            />
          </Animate>
        )}
        {showError ? (
          <Alert type="error" message="* Please select a location" showIcon/>
        ) : null}
      </Space>
        <Map
          defaultZoom={location.zoom}
          center={location.center}
          zoom={location.zoom}
          isMarkerShown={location.searchResultFound}
          setMapStatus={setMapStatus}
          location={location}
        />
        <div>
          <Button type="primary" block onClick={() => handleNext()}>
            Next
          </Button>
        </div>
        <BrowserView>
        <FooterText text="We’ve delivered over 500,000 photos" />
        </BrowserView>
    </Space>
      <Line />
      <MobileView>
        <Specialist />
      </MobileView>
    </Booking>
  );
};

export default StepMap;
