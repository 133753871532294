export const segmentTrack = async (title, payload) => {
  console.log(payload,'PAYLOADDDDDSSS')
  try {
    if (window.analytics) {
      await window.analytics.track(title, payload);
    }
  } catch (error) {
    console.log(error);
  }
};

export const segmentIdentify = async (payload) => {
  try {
    if (window.analytics) {
      await window.analytics.identify(payload);
    }
  } catch (error) {
    console.log(error);
  }
};
