import React, { useContext, useEffect, useState } from 'react';
import { Layout, Row, Col, Button, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import Line from 'components/Line';
import FooterText from 'components/FooterText';
import styles from './styles';
import cardStripe from './cardstripe.png';
import { MobileView, isMobile } from 'react-device-detect';
import { Context } from 'context';
import Specialist from 'components/Specialist';
import { segmentTrack } from 'actions/segment';
import { StripeAPIPublic } from 'config';
import { getProducts, gtmBooking } from '../../utils/Gtm';

const { Footer } = Layout;

const stripePromise = loadStripe(StripeAPIPublic);

const PaymentForm = () => {
  const [state] = useContext(Context);
  const [error, changeError] = useState('');
  const [spinner, toggleSpinner] = useState(false);
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
 
  const handleSubmit = async (event) => {
    const cardElement = elements.getElement(CardNumberElement);
    const { error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    }); 
    if (error) {
      changeError(error.message);
      setTimeout(() => {
        changeError('');
      }, 4000);
    } else {
      await makePayment(cardElement);
    }
  };

  const makePayment = async (cardElement) => {
    const { stripe_payment_intent_secret } = state.payments[0];
    toggleSpinner(true);
    stripe
      .confirmCardPayment(stripe_payment_intent_secret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: `${state.shoot.first_name} ${state.shoot.last_name}`,
          },
        },
      })
      .then((result) => {
        if (result.paymentIntent) {
          toggleSpinner(true);
          let addonObject = {};
          state.shoot.package.addons.forEach((addon) => {
            let addon_id = `addon_${addon.id}`;
            addonObject[addon_id] = false;
            if (state.shoot.addon_ids && state.shoot.addon_ids.includes(addon.id)) {
              addonObject[addon_id] = true;
            }
            return addonObject;
          });

          //GTM e-commerce tracking
          const products = getProducts(state)
          gtmBooking(state, products)

          history.push('/step/10');
        } else {
          changeError(error.message);
          setTimeout(() => {
            changeError('');
          }, 4000);
          toggleSpinner(false);
        }
      });
  };

  useEffect(() => {
    segmentTrack('Viewed Checkout Step', {
      step: '9',
      name: 'payment page',
    });
  });

  const inputInteracted = (input) => {
    segmentTrack('Input Interacted', { action: `${input}` });
  };
  console.log(state,'SSSSS')

  return (
    <>
      <Row>
        <Col span={24} style={styles.pt16}>
          <CardNumberElement
            id="cardNumber"
            className="ant-input"
            options={{ placeholder: '16 Digit Card Number' }}
            onChange={() => inputInteracted('card_number')}
          />
        </Col>
        <Col span={12} style={styles.pt16}>
          <CardExpiryElement
            id="expiry"
            className="ant-input"
            options={{ placeholder: 'Expiry Date' }}
            onChange={() => inputInteracted('card_expiry')}
          />
        </Col>
        <Col span={12} style={{ ...styles.pt16, ...styles.pl16 }}>
          <CardCvcElement
            id="cvc"
            className="ant-input"
            options={{ placeholder: 'CVC' }}
            onChange={() => inputInteracted('card_cvc')}
          />
        </Col>
      </Row>
      <div style={{ paddingTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', height: '3rem' }}>
        {/* <img src={cardStripe} /> */}

		    <img class="visa" src="https://s3-eu-west-1.amazonaws.com/perfocal-asset/images/visa.png"/>
		    <img class="mastercard" src="https://s3-eu-west-1.amazonaws.com/perfocal-asset/images/mastercard.svg"/>
		    <img class="amex" src="https://s3-eu-west-1.amazonaws.com/perfocal-asset/images/american_express.png"/>
		    <img class="stripe" src="https://s3-eu-west-1.amazonaws.com/perfocal-asset/images/stripe.svg"/>

      </div>
      {/* <Footer style={isMobile ? styles.footer : styles.footerWeb}> */}
        <div style={{ paddingTop: '20px', textAlign: 'center' }}>
          <p style={styles.error}>
            {error.length > 0 ? '* ' : null} {error}
          </p>
        </div>
        <div style={isMobile ? { margin: '0 0 1rem 0' } : null}>
          <Line />
        </div>
        <div style={isMobile ? styles.footer : null}>
          <Button  disabled={!stripe} type="primary" block onClick={handleSubmit}>
            {!spinner ? `Pay £${state.shoot.payment.total_amount / 100}` : <Spin />}
          </Button>
        </div>
        <MobileView>
        <Line />
          <Specialist />
        </MobileView>
      {/* </Footer> */}
    </>
  );
};

const StepPayment = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm />
    </Elements>
  );
};

export default StepPayment;
