import React from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import Spinner from 'components/Spinner';
import redMarkerIcon from './img/redmarker.png';
const mapStyle = require('./mapStyles.json');

const containerStyle = {
  height: '33vh',
  width: '100%',
  borderRadius: '6px',
};

const mapOptions = {
  styles: mapStyle,
  mapTypeControl: false,
  streetViewControl: false,
  overviewMapControl: false,
  scaleControl: false,
  rotateControl: false,
  fullscreenControl: false,
  panControl: false,
  scrollwheel: false,
  // gestureHandling: "none",
};

const libraries = ['places', 'geometry'];

const Map = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBDUbzPffEPKv7_BuN0xnTDtgJZQz7WQyw',
    libraries: libraries,
  });

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    const onLoad = () => {
      props.setMapStatus(true);
    };

    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={props.center}
        defaultZoom={props.defaultZoom}
        zoom={props.zoom}
        options={mapOptions}
        onLoad={onLoad}
      >
        {props.isMarkerShown && (
          <Marker
            icon={{
              url: redMarkerIcon,
              scaledSize: new window.google.maps.Size(40.75, 48),
            }}
            animation={window.google.maps.Animation.DROP}
            position={props.center}
          />
        )}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <Spinner />;
};

export default Map;
