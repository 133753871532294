import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import { debugContextDevtool } from 'react-context-devtool';
import { Helmet } from 'react-helmet';
import { SegmentAppId } from 'config';

const root = document.getElementById('root');

ReactDOM.render(
  <>
    <Helmet
      title={'New Booking | Perfocal'}
      titleTemplate={`New Booking | Perfocal`}
      meta={[
        {
          name: `description`,
          content:
            'Book a professional photographer for anything, in just a few clicks.',
        },
        {
          property: `og:title`,
          content: 'Book a photoshoot | Perfocal',
        },
        {
          property: `og:description`,
          content:
            'Book a professional photographer for anything, in just a few clicks.',
        },
        {
          property: `og:image`,
          content:
            'https://dkk5oy4zs0vc4.cloudfront.net/jumpstart/img/heros/hero-4.jpg',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'Perfocal',
        },
        {
          name: `twitter:title`,
          content: 'Book a photoshoot | Perfocal',
        },
        {
          name: `twitter:image`,
          content:
            'https://dkk5oy4zs0vc4.cloudfront.net/jumpstart/img/heros/hero-4.jpg',
        },
        {
          name: `twitter:description`,
          content:
            'Book a professional photographer for anything, in just a few clicks.',
        },
      ]}
    >
      <link
        href="https://dkk5oy4zs0vc4.cloudfront.net/favicon.ico"
        rel="shortcut icon"
        type="image/x-icon"
      />
      /**
      * Trustpilot Widget
      */
      <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
      <script type="text/javascript">
        {`
          !(function () {
            var analytics = (window.analytics = window.analytics || []);
            if (!analytics.initialize)
              if (analytics.invoked)
                window.console &&
                  console.error &&
                  console.error('Segment snippet included twice.');
              else {
                analytics.invoked = !0;
                analytics.methods = [
                  'trackSubmit',
                  'trackClick',
                  'trackLink',
                  'trackForm',
                  'pageview',
                  'identify',
                  'reset',
                  'group',
                  'track',
                  'ready',
                  'alias',
                  'debug',
                  'page',
                  'once',
                  'off',
                  'on',
                  'addSourceMiddleware',
                  'addIntegrationMiddleware',
                  'setAnonymousId',
                  'addDestinationMiddleware',
                ];
                analytics.factory = function (t) {
                  return function () {
                    var e = Array.prototype.slice.call(arguments);
                    e.unshift(t);
                    analytics.push(e);
                    return analytics;
                  };
                };
                for (var t = 0; t < analytics.methods.length; t++) {
                  var e = analytics.methods[t];
                  analytics[e] = analytics.factory(e);
                }
                analytics.load = function (t, e) {
                  var n = document.createElement('script');
                  n.type = 'text/javascript';
                  n.async = !0;
                  n.src =
                    'https://cdn.segment.com/analytics.js/v1/' +
                    t +
                    '/analytics.min.js';
                  var a = document.getElementsByTagName('script')[0];
                  a.parentNode.insertBefore(n, a);
                  analytics._loadOptions = e;
                };
                analytics.SNIPPET_VERSION = '4.1.0';
                analytics.load('${SegmentAppId}');
                analytics.page();
              }
          })()`}
      </script>

      <script src="https://fast.wistia.com/embed/medias/twcne895tx.jsonp" async></script>
      <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
      
    </Helmet>
    <App />
  </>,
  root
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// debugContextDevtool(root, {});
