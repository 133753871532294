import API from 'api';

export const setCheckedAddon = (payload) => {
  return { type: 'CHECK_ADDON', payload };
};

export const setShootAddons = (payload) => {
  return { type: 'SET_SHOOT_ADDONS', payload };
};

export const addToTotal = (payload) => {
  return { type: 'ADD_TO_TOTAL', payload };
};

export const removeFromTotal = (payload) => {
  return { type: 'REMOVE_FROM_TOTAL', payload };
};

export const setPromoCode = (payload) => {
  return { type: 'SET_PROMO_CODE', payload };
};

export const applyPromoCode = async (payment_intent_id, promocode) => {
  try {
    const response = await API.post('/promocodes/apply', {
      payment_intent_id,
      promocode,
    });
    return {
      type: 'APPLY_PROMO_CODE',
      payload: response.data,
    };
  } catch (error) {
    return { type: 'APPLY_PROMO_CODE_FAILED', payload: null, error: error };
  }
};

export const removePromoCode = () => {
  return { type: 'REMOVE_PROMO_CODE' };
};

export const toggleBookingLoading = () => {
  return { type: 'LOADING_SUBMIT_BOOKING' };
};

export const submitBooking = async (payload) => {
  try {
    const response = await API.post('/shoots', payload);
    return { type: 'SUBMIT_BOOKING', payload: response.data };
  } catch (error) {
    return { type: 'SUBMIT_BOOKING_FAILED', payload: error };
  }
};

export default {
  applyPromoCode,
  submitBooking,
};
