import React from 'react'
import PhoneInput from 'react-phone-input-2'

import './index.css'
import PropTypes from 'prop-types'

const CustomisedPhoneInput = (props) => {
  return (
    <PhoneInput
    country = {'gb'}
    preferredCountries = {['gb','us','fr','ie']}
    countryCodeEditable = {false}
    inputClass = "ant-input"
    dropdownClass = "box-shadow-3"
    {...props} />
  )
}

CustomisedPhoneInput.propTypes = {
  placeholder: PropTypes.string,
}

export default CustomisedPhoneInput;
