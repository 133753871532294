import { Theme } from 'theme';

export default {
  headline: {
    fontFamily: Theme.primaryFontFamily,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    color: '#3C3C4F',
  },
  pt16: {
    paddingTop: '16px',
  },
  pl16: {
    paddingLeft: '16px',
  },
  pt18: {
    paddingTop: 18,
  },
  footer: {
    width: '100%',
    zIndex: '99999999',
    position: 'sticky',
    bottom: '1rem',
    background: 'white'
  },
  footerWeb: {
    bottom: '1rem',
    backgroundColor: '#FFFFFF',
    padding: '0',
  },
  error: {
    color: Theme.error,
  },
};
