import { Theme } from 'theme';
import { isMobile } from 'react-device-detect';

export const style = {
  container: {
    position: 'relative',
    display: 'inline-block',
    margin: '0 auto',
  },
  button: {
    background: 'none',
    // borderRadius: isMobile ? '.25rem' : '.35rem',
    borderColor: 'none!important',
    color: 'none!important',
    // fontSize: '12.5px',
    padding: '0',
    margin: '.5rem',
    width: isMobile ? '45%' : '100%',
    height: isMobile ? '45%' : '80%',
  },
  buttonSelected: {
    background: 'none',
    borderRadius: isMobile ? '.25rem' : '.35rem',
    border: `3px solid ${Theme.primary}`,
    color: 'none!important',
    fontSize: '12.5px',
    padding: '0',
    margin: '.5rem',
    width: isMobile ? '45%' : '100%',
    height: isMobile ? '45%' : '80%',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    height: '8rem',
    width: isMobile ? '25rem' : '55rem',
    borderRadius: '.15rem',
    objectFit: 'cover',
  },
  dark: {
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',
    height: '100%',
    borderRadius: '.15rem',
    background: 'black',
    opacity: '0.3',
    position: 'absolute',
    zIndex: '99',
    margin: '0 auto',
    left: '0',
    right: '0',
    top: '0',
  },
  span: {
    position: 'absolute',
    color: 'white',
    fontWeight: '800',
    zIndex: '999',
    margin: '0 auto',
    left: '0',
    right: '0',
    top: '40%',
  },
  actionButton: {
    color: Theme.primary,
    textDecoration: 'underline',
    pointer: 'cursor',
  },
  row: {
    // marginRight: '-5px',
    // marginLeft: '-20px',
  },
};
