import React from 'react';
import { Theme } from 'theme';
import { CheckCircleOutlined } from '@ant-design/icons';

const styles = {
  container: {
    display: 'flex',
    fontSize: '16px',
    padding: '.5rem',
    background: '#f5fffa',
    border: `1px solid ${Theme.success}`,
    borderRadius: '1px',
  },
  icon: {
    color: Theme.success,
    margin: '0.25rem',
    fontSize: '35px',
  },
  text: {
    textAlign: 'left',
    marginLeft: '2rem',
    fontWeight: '400',
  },
  title: {
    fontSize: '20px',
  },
};

const Included = () => {
  return (
    <div style={styles.container}>
      <div>
        <CheckCircleOutlined style={styles.icon} />
      </div>
      <div style={styles.text}>
        <div style={styles.title}>What's included</div>
        <div>- 48 hours delivery</div>
        <div>- In-house natural editing</div>
        <div>- Unlimited photos</div>
      </div>
    </div>
  );
};

export default Included;
