import moment from 'moment';

const Reducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET_COMPLETED_STEP':
      return {
        ...state,
        step: payload,
      };
    case 'GET_SCENES':
      return {
        ...state,
        scenes: payload,
      };
    case 'GET_PACKAGES':
      return {
        ...state,
        packages: payload,
      };
    case 'SET_PROGRESS':
      return {
        ...state,
        progress: payload,
      };
    case 'SET_URGENT_BOOKING':
      return {
        ...state,
        urgent: payload,
      };
    case 'SET_URGENT_ADDED':
      return {
        ...state,
        addedUrgent: payload,
      };
    case 'SET_PURPOSE':
      return {
        ...state,
        purpose: payload,
      };
    case 'SET_SELECTED_DATE':
      if(payload.dateString.length) {
        const date = payload.time ? moment(payload.dateString + ' ' + payload.time).format(
          'YYYY-MM-DDTHH:mm:ss'
        ) :  moment(payload.dateString).format(
          'YYYY-MM-DD')
        return {
          ...state,
          shoot: { 
            ...state.shoot, 
            starts_at: date
          },
        };
      } else {
        return {
          ...state,
          shoot: { 
            ...state.shoot, 
            starts_at: null
          },
        };
      }
    case 'SET_SELECTED_TIME':
      const dateString = moment(state.shoot.starts_at).format('DD-MMM-YYYY');
      return {
        ...state,
        shoot: {
          ...state.shoot,
          starts_at: moment(dateString + ' ' + payload).format(
            'YYYY-MM-DDTHH:mm:ss'
          ),
        },
      };
    case 'SET_VALUE':
      const { value, name } = payload;
      return {
        ...state,
        shoot: { ...state.shoot, [name]: value },
      };
    case 'SET_SCENE':
      return {
        ...state,
        shoot: {
          ...state.shoot,
          scene: payload,
        },
      };
    case 'GET_ADDONS':
      return {
        ...state,
        addons: payload,
      };
    case 'SET_SPINNER':
      return {
        ...state,
        spinner: payload,
      };
    case 'SET_PACKAGE': {
      return {
        ...state,
        shoot: {
          ...state.shoot,
          package_id: payload.id,
          package: payload,
          initTotalAmount: payload.price,
          payment: { total_amount: payload.price },
        },
      };
    }
    case 'SET_ADDITIONAL_DETAILS':
      return {
        ...state,
        shoot: {
          ...state.shoot,
          additional_details: payload,
        },
      };
    case 'SET_LOCATION':
      const { lat, lng } = payload.latLng;
      return {
        ...state,
        shoot: {
          ...state.shoot,
          latitude: lat,
          longitude: lng,
        },
        location: {
          center: { lat, lng },
          zoom: 14,
          searchResultFound: true,
        }
      };
    case 'CLEAR_LOCATION':
      return {
        ...state,
        location: {
          center: {
            lat: 51.678853,
            lng: -0.186769,
          },
          zoom: 6,
          searchResultFound: false,
        }
      };
    case 'SET_ADDRESS':
      return {
        ...state,
        shoot: {
          ...state.shoot,
          address: payload,
        },
      };
    case 'SET_CITY':{
      let city = payload.address_components.filter(
        (address) =>
        address.types[0] === 'postal_town' ||
        address.types[0] === 'locality'    ||
        address.types[0] === 'establishment'    
      )
      return {
        ...state,
        shoot: {
          ...state.shoot,
          city: city[0]  ? city[0].long_name : '',
        },
      };
    }
    case 'CHECK_ADDON':
      return {
        ...state,
        shoot: {
          ...state.shoot,
          package: {
            ...state.shoot.package,
            ...state.shoot.addons,
            addons: state.shoot.package.addons.map((addon) => {
              if (addon.id === payload.id) {
                addon = payload;
              }
              return addon;
            }),
          },
        },
      };
    case 'SET_SHOOT_ADDONS':
      return {
        ...state,
        shoot: {
          ...state.shoot,
          addon_ids: payload,
        },
      };
    case 'SET_PROMO_CODE':
      return {
        ...state,
        promocodeText: payload,
      };
    case 'ADD_TO_TOTAL':
      return {
        ...state,
        shoot: {
          ...state.shoot,
          initTotalAmount: state.shoot.payment.total_amount + payload,
          payment: {
            total_amount: state.shoot.payment.total_amount + payload,
          },
        },
      };
    case 'REMOVE_FROM_TOTAL':
      return {
        ...state,
        shoot: {
          ...state.shoot,
          initTotalAmount: state.shoot.payment.total_amount - payload,
          payment: {
            total_amount: state.shoot.payment.total_amount - payload,
          },
        },
      };
    case 'APPLY_PROMO_CODE': {
      const { total_amount } = state.shoot.payment;
      const { promocode } = payload;

      let calculatedTotal = promocode.percentage
        ? total_amount - total_amount * (promocode.value / 100)
        : total_amount - promocode.value;

      return {
        ...state,
        promocode: payload.promocode,
        isPromoCodeApplied: true,
        promoCodeError: null,
        shoot: {
          ...state.shoot,
          payment: {
            total_amount: promocode.value > total_amount ? 0 : calculatedTotal,
          },
        },
      };
    }
    case 'APPLY_PROMO_CODE_FAILED':
      return {
        ...state,
        promocode: null,
        promoCodeError: 'Promo code is not valid.',
        isPromoCodeApplied: false,
      };

    case 'REMOVE_PROMO_CODE':
      return {
        ...state,
        promocode: null,
        isPromoCodeApplied: false,
        promoCodeError: null,
        shoot: {
          ...state.shoot,
          payment: {
            total_amount: state.shoot.initTotalAmount,
          },
        },
      };
    case 'MAKE_PAYMENT_FREE':
      return {
        ...state,
        isFreePaymentLoading: true,
      };
    case 'MAKE_PAYMENT_FREE_SUCCESS':
      return {
        ...state,
        isFreePaymentLoading: false,
      };
    case 'MAKE_PAYMENT_FREE_FAILED':
      return {
        ...state,
        isFreePaymentLoading: false,
      };
    case 'LOADING_SUBMIT_BOOKING':
      return {
        ...state,
        isBookingLoading: true,
      };
    case 'SUBMIT_BOOKING':
      localStorage.setItem('stateshoot', JSON.stringify(state));
      return {
        ...state,
        payments: payload.payments,
        shoot: {
          ...state.shoot,
          id: payload.id,
        },
        isBookingLoading: false,
      };

    case 'SUBMIT_BOOKING_FAILED':
      return {
        ...state,
        error: payload,
        isBookingLoading: false,
      };
    case 'HANDLE_GLOBAL_STATE':
      if (state.error) {
        return {
          state: JSON.parse(localStorage.getItem('stateshoot')),
        };
      } else {
        localStorage.setItem('stateshoot', JSON.stringify(state));
        return {
          ...state,
        };
      }

    default:
      return state;
  }
};

export default Reducer;
