import moment from 'moment';

export const setTimeString = (dateString) => {
  let time = null;
  if (dateString && dateString.substring(11, 13).length !== 0) {
    time = `${dateString.substring(11, 13)}:${dateString.substring(14, 16)}`;
  }
  return time;
};

export const checkTime = (dateString) => {
  return !dateString || dateString.substring(11, 13).length === 0;
};

export const isUrgent = (starts_at) => {
  const today = new Date();
  const tomorrow = new Date();
  return (
    moment(starts_at ? starts_at : null).endOf('day') <=
    moment(tomorrow.setDate(today.getDate() + 1)).endOf('day')
  );
};

export const configDateError = (dateString) => {
  const configDate = !dateString
    ? {
        rules: [
          { type: 'object', required: true, message: 'Please select a date!' },
        ],
      }
    : null;
  return configDate;
};

export const configTimeError = (dateString) => {
  let configTime = null;
  if(checkTime(dateString) ) {
    configTime = {
      rules: [
        { type: 'object', required: true, message: 'Please select a time!' },
      ],
    }
  }
  return configTime;
};
