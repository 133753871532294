import React from 'react';
import { Divider } from 'antd';
import { isMobile } from 'react-device-detect';

const Line = () => {
  return (
    <>
      {isMobile ? (
        <Divider dashed />
      ) : null}
    </>
  );
};

export default Line;
