import { Theme } from 'theme';
import { isMobile } from 'react-device-detect';

export default {
  headline: {
    fontFamily: Theme.primaryFontFamily,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#000000',
  },
  mainContainer: {
    scrollBehavior: 'smooth',
  },
  formContainer: {
    paddingTop: 20,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: '15px',
    marginBottom: '24px'
  },
  footer: {
    width: '92.5%',
    backgroundColor: '#FFFFFF',
    padding: '0',
    position: 'fixed',
    bottom: '.25rem',
  },
  footerWeb: {
    overflow: 'none',
    bottom: '1rem',
    backgroundColor: '#FFFFFF',
    padding: '1rem 0',
  },
  linkContainer: {
    textAlign: 'center',
    margin: '1rem',
  },
  buttonWhite: {
    background: 'white',
    borderColor: Theme.primary,
    color: Theme.primary,
    fontSize: Theme.fontSize,
    fontWeight: Theme.fontWeight,
    width: '100%',
  },
  container: {
    fontFamily: Theme.primaryFontFamily,
    fontSize: isMobile ? '14px' : '16px',
    color: '#3C3C4F',
    border: `1px solid #ffe58f`,
    background: '#fffbe6',
    padding: '12px',
    borderRadius: '2px',
  },
  text: {
    margin: '0',
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: 'rgb(60, 60, 79)',
  },
};
