
const productObject = (sku, name, category, price) => {
  return {
    sku: sku,
    name: name,
    category: category,
    price: price,
    currency: 'GBP',
    quantity: 1,
  }
}

export const getProducts = (state) => {
  let products = [productObject(
    `PKG-${state.shoot.package.id}`,
    `${state.shoot.scene.name} - ${state.shoot.package.name}`,
    state.shoot.scene.name,state.shoot.package.price / 100)
  ];
  state.addons.forEach((addon) => {
    if (state.shoot.addon_ids.length > 0) {
      state.shoot.addon_ids.forEach((shootAddon) => {
        if (addon.id === shootAddon) {
          products.push(productObject(
            `ADDON-${addon.id}`,
            `${addon.name}`,
            'add-on',
            addon.price / 100
            )
          );
        }
      });
    }
  });
  if(state.promocode) {
    if(state.promocode.percentage) {
      let total = products.length > 1 ? products.reduce((accumulator, currentValue) => { return accumulator.price + currentValue.price}) : products[0].price
      products.push(
        productObject(`PROMO-${state.promocode.id}`,`PROMO-${state.promocode.name}`,'promocode',(total * (state.promocode.value / 100)) * -1)
      );
    } else {
      products.push(
        productObject(`PROMO-${state.promocode.id}`,`PROMO-${state.promocode.name}`,'promocode',(state.promocode.value / 100) * -1)
      );
    }
  }
  console.log(products)

  return products
}

export const gtmBooking = (state, products) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    booking_total: state.shoot.payment.total_amount / 100,
    order_id: state.shoot.id,
    scene_name: state.shoot.scene.name,
    package_name: state.shoot.package.name,
    transactionId: state.shoot.id,
    transactionAffiliation: 'Perfocal',
    currencyCode: 'GBP',
    currency: 'GBP',
    transactionTotal: state.shoot.payment.total_amount / 100,
    transactionProducts: products,
  });

  dataLayer.push({
    event: 'booking-paid',
  });
}