import React from 'react';
import PropTypes from 'prop-types';
import { Theme } from 'theme';

const styles = {
  label: {
    fontFamily: Theme.primaryFontFamily,
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '0.2px',
    fontWeight: 'bold',
    color: 'rgba(72, 73, 99, 0.65)',
  }
}

const Label = ({ text }) => {
  return (
    <label style={styles.label}>
      {text}
    </label>
  )
}

Label.propTypes = {
  text: PropTypes.string,
};

export default Label;