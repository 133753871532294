import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button, Card, Typography, Modal } from 'antd';
import { Context } from 'context';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { segmentTrack } from 'actions/segment';

import { style } from './style';
import { setProgress } from 'actions/progress';
import { setScene, getPackages } from 'actions/scene';
import { PlusOutlined } from '@ant-design/icons';
import CarouselComponent from 'components/_Carousel';

const initialScenes = isMobile ? 6 : 8;
const { Meta } = Card;
const { Title, Text } = Typography;

const SelectScene = () => {
  let history = useHistory();
  const [state, dispatch] = useContext(Context);
  const [showAll, toggleShow] = useState(false);
  const [modal, toggleModal] = useState(false);
  const [sceneSelected, selectScene] = useState(null);

  const [scenes] = useState(
    state.scenes.filter((scene) => {
      return (
        scene.purpose ===
        (state.purpose === 'For Personal' ? 'Personal' : 'Business')
      );
    })
  );

  const showMore = (boolean) => {
    toggleShow(boolean);
    segmentTrack('Show More Clicked', {});
  };

  useEffect(() => {
    if (scenes.length < initialScenes) {
      toggleShow(true);
    }
    let index = state.shoot.scene ? scenes.indexOf(state.shoot.scene) : null
    if(state.shoot.scene && (index + 1) > initialScenes) {
      toggleShow(true)
    }
    segmentTrack('View Checkout Step', { step: '2', name: 'scene' });
  }, [scenes, state.shoot.scene]);

  const handleClick = (scene) => {
    selectScene(scene)
    toggleModal(true)
  };

  const handleOk = async () => {
    history.push('/step/3');
    dispatch(setProgress(state.progress + 11));
    dispatch(setScene(sceneSelected));
    dispatch(await getPackages(sceneSelected.id));
    segmentTrack('Scene Clicked', { scene: sceneSelected.id });
    segmentTrack('Completed Checkout Step', { step: '2', name: 'scene' });
  }

  return (
    <>
     <Modal width={'100rem'} title={sceneSelected ? `${sceneSelected.name} Photography` : ''} okText='Yes, continue' cancelText={'Go back'} visible={modal} onOk={handleOk} onCancel={() => toggleModal(false)}>
        <p>{sceneSelected ? sceneSelected.description : ''}</p>
        <br/>
        <CarouselComponent scene={sceneSelected} /> 
        <br/>
        <div style={{textAlign:'center'}}>
          <Text style={{color: '#6C6D82'}}>All images taken by Perfocal</Text>
        </div>
      </Modal>
      <BrowserView>
        <Row gutter={[24, { xs: 16, sm: 24, md: 32, lg: 40 }]}>
          {scenes
            .slice(0, showAll ? scenes.length : initialScenes)
            .map((scene, index) => {
              let isSelected =
                state.shoot.scene === scene
                  ? style.buttonSelected
                  : style.button;
              return (
                <Col sm={12} md={8} lg={6} key={index}>
                  <Card
                    hoverable
                    cover = {
                      <>
                      <img alt={scene.name} src={scene.image} height={155} style={{objectFit:'cover'}}/>
                      </>
                    }
                    bordered={true}
                    size = {"small"}
                    className = "scenes"
                    onClick={() => handleClick(scene)}
                  >
                  <Title level={5} className="display">{scene.name}</Title>
                  </Card>
                </Col>
              );
            })}
        </Row>
        {!showAll ? (
          <div style={{ textAlign: 'center' }}>
          <Button type="" size={'small'} icon={<PlusOutlined />} onClick={() => showMore(true)}>Show more</Button>
          </div>
        ) : null}
      </BrowserView>


      <MobileView>
        {scenes
          .slice(0, showAll ? scenes.length : initialScenes)
          .map((scene, index) => {
            let isSelected =
              state.shoot.scene === scene ? style.buttonSelected : style.button;
            return (
              <Button
                className="customButton"
                style={isSelected}
                onClick={() => handleClick(scene)}
                key={index}
              >
                <img style={style.img} src={scene.image} alt="scene" />
                <p style={style.span}>{scene.name}</p>
                <div style={style.dark}></div>
              </Button>
            );
          })}
        {!showAll ? (
          <div style={{ textAlign: 'center', cursor: 'pointer' }}>
            <span style={style.actionButton} onClick={() => showMore(true)}>
              Show more
            </span>
          </div>
        ) : null}
      </MobileView>
    </>
  );
};

export default SelectScene;
