import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Row } from 'antd'

import { isMobile } from 'react-device-detect';

const { Text } = Typography;


const FooterText = ({ text }) => {
  return (
    <Row justify="center">
      <Text type="secondary" className="text-small">
        {text}
      </Text >
    </Row>
  )
}

FooterText.propTypes = {
  text: PropTypes.string,
};

export default FooterText;
